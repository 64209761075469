@font-face {
  font-family: 'ma_weather_ms01regular';
  src: url('#{$font-path}maweather-ms01-webfont.eot');
  src: url('#{$font-path}maweather-ms01-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}maweather-ms01-webfont.woff') format('woff'),
  url('#{$font-path}maweather-ms01-webfont.ttf') format('truetype'),
  url('#{$font-path}maweather-ms01-webfont.svg#ma_weather_ms01regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

.wi {
  font-family: 'ma_weather_ms01regular';
}
