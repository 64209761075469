@charset "UTF-8";
@import "../../node_modules/primeng/resources/primeng.min.css";
@import "../../node_modules/primeicons/primeicons.css";
@import "../../node_modules/prismjs/themes/prism-coy.css";
.p-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
  margin-top: -1rem;
}

.p-grid > .p-col,
.p-grid > [class*=p-col] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.p-nogutter {
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}

.p-nogutter > .p-col,
.p-nogutter > [class*=p-col-] {
  padding: 0;
}

.p-col {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  padding: 1rem;
}

.p-col-fixed {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 1rem;
}

.p-col-1,
.p-col-2,
.p-col-3,
.p-col-4,
.p-col-5,
.p-col-6,
.p-col-7,
.p-col-8,
.p-col-9,
.p-col-10,
.p-col-11,
.p-col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 1rem;
}

.p-col-1 {
  width: 8.3333%;
}

.p-col-2 {
  width: 16.6667%;
}

.p-col-3 {
  width: 25%;
}

.p-col-4 {
  width: 33.3333%;
}

.p-col-5 {
  width: 41.6667%;
}

.p-col-6 {
  width: 50%;
}

.p-col-7 {
  width: 58.3333%;
}

.p-col-8 {
  width: 66.6667%;
}

.p-col-9 {
  width: 75%;
}

.p-col-10 {
  width: 83.3333%;
}

.p-col-11 {
  width: 91.6667%;
}

.p-col-12 {
  width: 100%;
}

.p-offset-12 {
  margin-left: 100%;
}

.p-offset-11 {
  margin-left: 91.66666667%;
}

.p-offset-10 {
  margin-left: 83.33333333%;
}

.p-offset-9 {
  margin-left: 75%;
}

.p-offset-8 {
  margin-left: 66.66666667%;
}

.p-offset-7 {
  margin-left: 58.33333333%;
}

.p-offset-6 {
  margin-left: 50%;
}

.p-offset-5 {
  margin-left: 41.66666667%;
}

.p-offset-4 {
  margin-left: 33.33333333%;
}

.p-offset-3 {
  margin-left: 25%;
}

.p-offset-2 {
  margin-left: 16.66666667%;
}

.p-offset-1 {
  margin-left: 8.33333333%;
}

.p-offset-0 {
  margin-left: 0%;
}

.p-sm-1,
.p-sm-2,
.p-sm-3,
.p-sm-4,
.p-sm-5,
.p-sm-6,
.p-sm-7,
.p-sm-8,
.p-sm-9,
.p-sm-10,
.p-sm-11,
.p-sm-12,
.p-md-1,
.p-md-2,
.p-md-3,
.p-md-4,
.p-md-5,
.p-md-6,
.p-md-7,
.p-md-8,
.p-md-9,
.p-md-10,
.p-md-11,
.p-md-12,
.p-lg-1,
.p-lg-2,
.p-lg-3,
.p-lg-4,
.p-lg-5,
.p-lg-6,
.p-lg-7,
.p-lg-8,
.p-lg-9,
.p-lg-10,
.p-lg-11,
.p-lg-12,
.p-xl-1,
.p-xl-2,
.p-xl-3,
.p-xl-4,
.p-xl-5,
.p-xl-6,
.p-xl-7,
.p-xl-8,
.p-xl-9,
.p-xl-10,
.p-xl-11,
.p-xl-12 {
  padding: 1rem;
}

.p-col-nogutter {
  padding: 0;
}

@media screen and (min-width: 576px) {
  .p-sm-1,
.p-sm-2,
.p-sm-3,
.p-sm-4,
.p-sm-5,
.p-sm-6,
.p-sm-7,
.p-sm-8,
.p-sm-9,
.p-sm-10,
.p-sm-11,
.p-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .p-sm-1 {
    width: 8.3333%;
  }

  .p-sm-2 {
    width: 16.6667%;
  }

  .p-sm-3 {
    width: 25%;
  }

  .p-sm-4 {
    width: 33.3333%;
  }

  .p-sm-5 {
    width: 41.6667%;
  }

  .p-sm-6 {
    width: 50%;
  }

  .p-sm-7 {
    width: 58.3333%;
  }

  .p-sm-8 {
    width: 66.6667%;
  }

  .p-sm-9 {
    width: 75%;
  }

  .p-sm-10 {
    width: 83.3333%;
  }

  .p-sm-11 {
    width: 91.6667%;
  }

  .p-sm-12 {
    width: 100%;
  }

  .p-sm-offset-12 {
    margin-left: 100%;
  }

  .p-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-sm-offset-9 {
    margin-left: 75%;
  }

  .p-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-sm-offset-6 {
    margin-left: 50%;
  }

  .p-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-sm-offset-3 {
    margin-left: 25%;
  }

  .p-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-sm-offset-0 {
    margin-left: 0%;
  }
}
@media screen and (min-width: 768px) {
  .p-md-1,
.p-md-2,
.p-md-3,
.p-md-4,
.p-md-5,
.p-md-6,
.p-md-7,
.p-md-8,
.p-md-9,
.p-md-10,
.p-md-11,
.p-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .p-md-1 {
    width: 8.3333%;
  }

  .p-md-2 {
    width: 16.6667%;
  }

  .p-md-3 {
    width: 25%;
  }

  .p-md-4 {
    width: 33.3333%;
  }

  .p-md-5 {
    width: 41.6667%;
  }

  .p-md-6 {
    width: 50%;
  }

  .p-md-7 {
    width: 58.3333%;
  }

  .p-md-8 {
    width: 66.6667%;
  }

  .p-md-9 {
    width: 75%;
  }

  .p-md-10 {
    width: 83.3333%;
  }

  .p-md-11 {
    width: 91.6667%;
  }

  .p-md-12 {
    width: 100%;
  }

  .p-md-offset-12 {
    margin-left: 100%;
  }

  .p-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-md-offset-9 {
    margin-left: 75%;
  }

  .p-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-md-offset-6 {
    margin-left: 50%;
  }

  .p-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-md-offset-3 {
    margin-left: 25%;
  }

  .p-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-md-offset-0 {
    margin-left: 0%;
  }
}
@media screen and (min-width: 992px) {
  .p-lg-1,
.p-lg-2,
.p-lg-3,
.p-lg-4,
.p-lg-5,
.p-lg-6,
.p-lg-7,
.p-lg-8,
.p-lg-9,
.p-lg-10,
.p-lg-11,
.p-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .p-lg-1 {
    width: 8.3333%;
  }

  .p-lg-2 {
    width: 16.6667%;
  }

  .p-lg-3 {
    width: 25%;
  }

  .p-lg-4 {
    width: 33.3333%;
  }

  .p-lg-5 {
    width: 41.6667%;
  }

  .p-lg-6 {
    width: 50%;
  }

  .p-lg-7 {
    width: 58.3333%;
  }

  .p-lg-8 {
    width: 66.6667%;
  }

  .p-lg-9 {
    width: 75%;
  }

  .p-lg-10 {
    width: 83.3333%;
  }

  .p-lg-11 {
    width: 91.6667%;
  }

  .p-lg-12 {
    width: 100%;
  }

  .p-lg-offset-12 {
    margin-left: 100%;
  }

  .p-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-lg-offset-9 {
    margin-left: 75%;
  }

  .p-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-lg-offset-6 {
    margin-left: 50%;
  }

  .p-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-lg-offset-3 {
    margin-left: 25%;
  }

  .p-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-lg-offset-0 {
    margin-left: 0%;
  }
}
@media screen and (min-width: 1200px) {
  .p-xl-1,
.p-xl-2,
.p-xl-3,
.p-xl-4,
.p-xl-5,
.p-xl-6,
.p-xl-7,
.p-xl-8,
.p-xl-9,
.p-xl-10,
.p-xl-11,
.p-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .p-xl-1 {
    width: 8.3333%;
  }

  .p-xl-2 {
    width: 16.6667%;
  }

  .p-xl-3 {
    width: 25%;
  }

  .p-xl-4 {
    width: 33.3333%;
  }

  .p-xl-5 {
    width: 41.6667%;
  }

  .p-xl-6 {
    width: 50%;
  }

  .p-xl-7 {
    width: 58.3333%;
  }

  .p-xl-8 {
    width: 66.6667%;
  }

  .p-xl-9 {
    width: 75%;
  }

  .p-xl-10 {
    width: 83.3333%;
  }

  .p-xl-11 {
    width: 91.6667%;
  }

  .p-xl-12 {
    width: 100%;
  }

  .p-xl-offset-12 {
    margin-left: 100%;
  }

  .p-xl-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-xl-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-xl-offset-9 {
    margin-left: 75%;
  }

  .p-xl-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-xl-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-xl-offset-6 {
    margin-left: 50%;
  }

  .p-xl-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-xl-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-xl-offset-3 {
    margin-left: 25%;
  }

  .p-xl-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-xl-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-xl-offset-0 {
    margin-left: 0%;
  }
}
.p-justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.p-justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.p-justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.p-justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p-justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.p-justify-even {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.p-align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p-align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.p-align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-align-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.p-align-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.p-col-align-start {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.p-col-align-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.p-col-align-center {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.p-col-align-baseline {
  -ms-flex-item-align: baseline;
  align-self: baseline;
}

.p-col-align-stretch {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.p-dir-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.p-dir-rev {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.p-dir-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.p-dir-col-rev {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.p-dir-col > .p-col,
.p-dir-col-rev > .p-col {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.p-col-order-first {
  -ms-flex-order: -1;
  order: -1;
}

.p-col-order-last {
  -ms-flex-order: 13;
  order: 13;
}

.p-col-order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.p-col-order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.p-col-order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.p-col-order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.p-col-order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.p-col-order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.p-col-order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.p-col-order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.p-col-order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.p-col-order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.p-col-order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.p-col-order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.p-col-order-12 {
  -ms-flex-order: 12;
  order: 12;
}

@media screen and (min-width: 576px) {
  .p-sm-order-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .p-sm-order-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .p-sm-order-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .p-sm-order-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .p-sm-order-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .p-sm-order-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .p-sm-order-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .p-sm-order-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .p-sm-order-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .p-sm-order-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .p-sm-order-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .p-sm-order-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .p-sm-order-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .p-sm-order-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .p-sm-order-12 {
    -ms-flex-order: 12;
    order: 12;
  }
}
@media screen and (min-width: 768px) {
  .p-md-order-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .p-md-order-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .p-md-order-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .p-md-order-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .p-md-order-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .p-md-order-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .p-md-order-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .p-md-order-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .p-md-order-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .p-md-order-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .p-md-order-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .p-md-order-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .p-md-order-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .p-md-order-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .p-md-order-12 {
    -ms-flex-order: 12;
    order: 12;
  }
}
@media screen and (min-width: 992px) {
  .p-lg-order-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .p-lg-order-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .p-lg-order-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .p-lg-order-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .p-lg-order-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .p-lg-order-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .p-lg-order-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .p-lg-order-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .p-lg-order-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .p-lg-order-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .p-lg-order-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .p-lg-order-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .p-lg-order-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .p-lg-order-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .p-lg-order-12 {
    -ms-flex-order: 12;
    order: 12;
  }
}
@media screen and (min-width: 1200px) {
  .p-xl-order-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .p-xl-order-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .p-xl-order-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .p-xl-order-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .p-xl-order-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .p-xl-order-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .p-xl-order-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .p-xl-order-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .p-xl-order-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .p-xl-order-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .p-xl-order-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .p-xl-order-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .p-xl-order-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .p-xl-order-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .p-xl-order-12 {
    -ms-flex-order: 12;
    order: 12;
  }
}
.p-field {
  margin-bottom: 1rem;
}

.p-field > label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.p-field.p-grid > label {
  display: flex;
  align-items: center;
}

.p-field > small {
  margin-top: 0.25rem;
}

.p-field.p-grid,
.p-formgrid.p-grid {
  margin-top: 0;
}

.p-field.p-grid .p-col-fixed,
.p-formgrid.p-grid .p-col-fixed,
.p-field.p-grid .p-col,
.p-formgrid.p-grid .p-col,
.p-field.p-grid .p-col-1,
.p-formgrid.p-grid .p-col-1,
.p-field.p-grid .p-col-2,
.p-formgrid.p-grid .p-col-2,
.p-field.p-grid .p-col-3,
.p-formgrid.p-grid .p-col-3,
.p-field.p-grid .p-col-4,
.p-formgrid.p-grid .p-col-4,
.p-field.p-grid .p-col-5,
.p-formgrid.p-grid .p-col-5,
.p-field.p-grid .p-col-6,
.p-formgrid.p-grid .p-col-6,
.p-field.p-grid .p-col-7,
.p-formgrid.p-grid .p-col-7,
.p-field.p-grid .p-col-8,
.p-formgrid.p-grid .p-col-8,
.p-field.p-grid .p-col-9,
.p-formgrid.p-grid .p-col-9,
.p-field.p-grid .p-col-10,
.p-formgrid.p-grid .p-col-10,
.p-field.p-grid .p-col-11,
.p-formgrid.p-grid .p-col-11,
.p-field.p-grid .p-col-12,
.p-formgrid.p-grid .p-col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

.p-formgroup-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.p-formgroup-inline .p-field,
.p-formgroup-inline .p-field-checkbox,
.p-formgroup-inline .p-field-radiobutton {
  margin-right: 1rem;
}

.p-formgroup-inline .p-field > label,
.p-formgroup-inline .p-field-checkbox > label,
.p-formgroup-inline .p-field-radiobutton > label {
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.p-field-checkbox,
.p-field-radiobutton {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.p-field-checkbox > label,
.p-field-radiobutton > label {
  margin-left: 0.5rem;
  line-height: 1;
}

.p-d-none {
  display: none !important;
}

.p-d-inline {
  display: inline !important;
}

.p-d-inline-block {
  display: inline-block !important;
}

.p-d-block {
  display: block !important;
}

.p-d-flex {
  display: flex !important;
}

.p-d-inline-flex {
  display: inline-flex !important;
}

@media screen and (min-width: 576px) {
  .p-d-sm-none {
    display: none !important;
  }

  .p-d-sm-inline {
    display: inline !important;
  }

  .p-d-sm-inline-block {
    display: inline-block !important;
  }

  .p-d-sm-block {
    display: block !important;
  }

  .p-d-sm-flex {
    display: flex !important;
  }

  .p-d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 768px) {
  .p-d-md-none {
    display: none !important;
  }

  .p-d-md-inline {
    display: inline !important;
  }

  .p-d-md-inline-block {
    display: inline-block !important;
  }

  .p-d-md-block {
    display: block !important;
  }

  .p-d-md-flex {
    display: flex !important;
  }

  .p-d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 992px) {
  .p-d-lg-none {
    display: none !important;
  }

  .p-d-lg-inline {
    display: inline !important;
  }

  .p-d-lg-inline-block {
    display: inline-block !important;
  }

  .p-d-lg-block {
    display: block !important;
  }

  .p-d-lg-flex {
    display: flex !important;
  }

  .p-d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 1200px) {
  .p-d-xl-none {
    display: none !important;
  }

  .p-d-xl-inline {
    display: inline !important;
  }

  .p-d-xl-inline-block {
    display: inline-block !important;
  }

  .p-d-xl-block {
    display: block !important;
  }

  .p-d-xl-flex {
    display: flex !important;
  }

  .p-d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .p-d-print-none {
    display: none !important;
  }

  .p-d-print-inline {
    display: inline !important;
  }

  .p-d-print-inline-block {
    display: inline-block !important;
  }

  .p-d-print-block {
    display: block !important;
  }

  .p-d-print-flex {
    display: flex !important;
  }

  .p-d-print-inline-flex {
    display: inline-flex !important;
  }
}
.p-text-justify {
  text-align: justify !important;
}

.p-text-left {
  text-align: left !important;
}

.p-text-right {
  text-align: right !important;
}

.p-text-center {
  text-align: center !important;
}

.p-text-nowrap {
  white-space: nowrap !important;
}

.p-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.p-text-lowercase {
  text-transform: lowercase !important;
}

.p-text-uppercase {
  text-transform: uppercase !important;
}

.p-text-capitalize {
  text-transform: capitalize !important;
}

.p-text-bold {
  font-weight: 700 !important;
}

.p-text-normal {
  font-weight: 400 !important;
}

.p-text-light {
  font-weight: 300 !important;
}

.p-text-italic {
  font-style: italic !important;
}

@media screen and (min-width: 576px) {
  .p-text-sm-justify {
    text-align: justify !important;
  }

  .p-text-sm-left {
    text-align: left !important;
  }

  .p-text-sm-right {
    text-align: right !important;
  }

  .p-text-sm-center {
    text-align: center !important;
  }
}
@media screen and (min-width: 768px) {
  .p-text-md-justify {
    text-align: justify !important;
  }

  .p-text-md-left {
    text-align: left !important;
  }

  .p-text-md-right {
    text-align: right !important;
  }

  .p-text-md-center {
    text-align: center !important;
  }
}
@media screen and (min-width: 992px) {
  .p-text-lg-justify {
    text-align: justify !important;
  }

  .p-text-lg-left {
    text-align: left !important;
  }

  .p-text-lg-right {
    text-align: right !important;
  }

  .p-text-lg-center {
    text-align: center !important;
  }
}
@media screen and (min-width: 1200px) {
  .p-text-xl-justify {
    text-align: justify !important;
  }

  .p-text-xl-left {
    text-align: left !important;
  }

  .p-text-xl-right {
    text-align: right !important;
  }

  .p-text-xl-center {
    text-align: center !important;
  }
}
.p-flex-row {
  flex-direction: row !important;
}

.p-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.p-flex-column {
  flex-direction: column !important;
}

.p-flex-column-reverse {
  flex-direction: column-reverse !important;
}

@media screen and (min-width: 576px) {
  .p-flex-sm-row {
    flex-direction: row !important;
  }

  .p-flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .p-flex-sm-column {
    flex-direction: column !important;
  }

  .p-flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 768px) {
  .p-flex-md-row {
    flex-direction: row !important;
  }

  .p-flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .p-flex-md-column {
    flex-direction: column !important;
  }

  .p-flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 992px) {
  .p-flex-lg-row {
    flex-direction: row !important;
  }

  .p-flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .p-flex-lg-column {
    flex-direction: column !important;
  }

  .p-flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 1200px) {
  .p-flex-xl-row {
    flex-direction: row !important;
  }

  .p-flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .p-flex-xl-column {
    flex-direction: column !important;
  }

  .p-flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
}
.p-jc-start {
  justify-content: flex-start;
}

.p-jc-end {
  justify-content: flex-end;
}

.p-jc-center {
  justify-content: center;
}

.p-jc-between {
  justify-content: space-between;
}

.p-jc-around {
  justify-content: space-around;
}

.p-jc-evenly {
  justify-content: space-evenly;
}

@media screen and (min-width: 576px) {
  .p-jc-sm-start {
    justify-content: flex-start;
  }

  .p-jc-sm-end {
    justify-content: flex-end;
  }

  .p-jc-sm-center {
    justify-content: center;
  }

  .p-jc-sm-between {
    justify-content: space-between;
  }

  .p-jc-sm-around {
    justify-content: space-around;
  }

  .p-jc-sm-evenly {
    justify-content: space-evenly;
  }
}
@media screen and (min-width: 768px) {
  .p-jc-md-start {
    justify-content: flex-start;
  }

  .p-jc-md-end {
    justify-content: flex-end;
  }

  .p-jc-md-center {
    justify-content: center;
  }

  .p-jc-md-between {
    justify-content: space-between;
  }

  .p-jc-md-around {
    justify-content: space-around;
  }

  .p-jc-md-evenly {
    justify-content: space-evenly;
  }
}
@media screen and (min-width: 992px) {
  .p-jc-lg-start {
    justify-content: flex-start;
  }

  .p-jc-lg-end {
    justify-content: flex-end;
  }

  .p-jc-lg-center {
    justify-content: center;
  }

  .p-jc-lg-between {
    justify-content: space-between;
  }

  .p-jc-lg-around {
    justify-content: space-around;
  }

  .p-jc-lg-evenly {
    justify-content: space-evenly;
  }
}
@media screen and (min-width: 1200px) {
  .p-jc-xl-start {
    justify-content: flex-start;
  }

  .p-jc-xl-end {
    justify-content: flex-end;
  }

  .p-jc-xl-center {
    justify-content: center;
  }

  .p-jc-xl-between {
    justify-content: space-between;
  }

  .p-jc-xl-around {
    justify-content: space-around;
  }

  .p-jc-xl-evenly {
    justify-content: space-evenly;
  }
}
.p-ai-start {
  align-items: flex-start;
}

.p-ai-end {
  align-items: flex-end;
}

.p-ai-center {
  align-items: center;
}

.p-ai-baseline {
  align-items: baseline;
}

.p-ai-stretch {
  align-items: stretch;
}

@media screen and (min-width: 576px) {
  .p-ai-sm-start {
    align-items: flex-start;
  }

  .p-ai-sm-end {
    align-items: flex-end;
  }

  .p-ai-sm-center {
    align-items: center;
  }

  .p-ai-sm-baseline {
    align-items: baseline;
  }

  .p-ai-sm-stretch {
    align-items: stretch;
  }
}
@media screen and (min-width: 768px) {
  .p-ai-md-start {
    align-items: flex-start;
  }

  .p-ai-md-end {
    align-items: flex-end;
  }

  .p-ai-md-center {
    align-items: center;
  }

  .p-ai-md-baseline {
    align-items: baseline;
  }

  .p-ai-md-stretch {
    align-items: stretch;
  }
}
@media screen and (min-width: 992px) {
  .p-ai-lg-start {
    align-items: flex-start;
  }

  .p-ai-lg-end {
    align-items: flex-end;
  }

  .p-ai-lg-center {
    align-items: center;
  }

  .p-ai-lg-baseline {
    align-items: baseline;
  }

  .p-ai-lg-stretch {
    align-items: stretch;
  }
}
@media screen and (min-width: 1200px) {
  .p-ai-xl-start {
    align-items: flex-start;
  }

  .p-ai-xl-end {
    align-items: flex-end;
  }

  .p-ai-xl-center {
    align-items: center;
  }

  .p-ai-xl-baseline {
    align-items: baseline;
  }

  .p-ai-xl-stretch {
    align-items: stretch;
  }
}
.p-as-start {
  align-self: start;
}

.p-as-end {
  align-self: flex-end;
}

.p-as-center {
  align-self: center;
}

.p-as-baseline {
  align-self: baseline;
}

.p-as-stretch {
  align-self: stretch;
}

@media screen and (min-width: 576px) {
  .p-as-sm-start {
    align-self: start;
  }

  .p-as-sm-end {
    align-self: flex-end;
  }

  .p-as-sm-center {
    align-self: center;
  }

  .p-as-sm-baseline {
    align-self: baseline;
  }

  .p-as-sm-stretch {
    align-self: stretch;
  }
}
@media screen and (min-width: 768px) {
  .p-as-md-start {
    align-self: start;
  }

  .p-as-md-end {
    align-self: flex-end;
  }

  .p-as-md-center {
    align-self: center;
  }

  .p-as-md-baseline {
    align-self: baseline;
  }

  .p-as-md-stretch {
    align-self: stretch;
  }
}
@media screen and (min-width: 992px) {
  .p-as-lg-start {
    align-self: start;
  }

  .p-as-lg-end {
    align-self: flex-end;
  }

  .p-as-lg-center {
    align-self: center;
  }

  .p-as-lg-baseline {
    align-self: baseline;
  }

  .p-as-lg-stretch {
    align-self: stretch;
  }
}
@media screen and (min-width: 1200px) {
  .p-as-xl-start {
    align-self: start;
  }

  .p-as-xl-end {
    align-self: flex-end;
  }

  .p-as-xl-center {
    align-self: center;
  }

  .p-as-xl-baseline {
    align-self: baseline;
  }

  .p-as-xl-stretch {
    align-self: stretch;
  }
}
.p-ac-start {
  align-content: flex-start;
}

.p-ac-end {
  align-content: flex-end;
}

.p-ac-center {
  align-content: center;
}

.p-ac-around {
  align-content: space-around;
}

.p-ac-stretch {
  align-content: stretch;
}

.p-ac-between {
  align-content: space-between;
}

@media screen and (min-width: 576px) {
  .p-ac-sm-start {
    align-content: flex-start;
  }

  .p-ac-sm-end {
    align-content: flex-end;
  }

  .p-ac-sm-center {
    align-content: center;
  }

  .p-ac-sm-around {
    align-content: space-around;
  }

  .p-ac-sm-stretch {
    align-content: stretch;
  }

  .p-ac-sm-between {
    align-content: space-between;
  }
}
@media screen and (min-width: 768px) {
  .p-ac-md-start {
    align-content: flex-start;
  }

  .p-ac-md-end {
    align-content: flex-end;
  }

  .p-ac-md-center {
    align-content: center;
  }

  .p-ac-md-around {
    align-content: space-around;
  }

  .p-ac-md-stretch {
    align-content: stretch;
  }

  .p-ac-md-between {
    align-content: space-between;
  }
}
@media screen and (min-width: 992px) {
  .p-ac-lg-start {
    align-content: flex-start;
  }

  .p-ac-lg-end {
    align-content: flex-end;
  }

  .p-ac-lg-center {
    align-content: center;
  }

  .p-ac-lg-around {
    align-content: space-around;
  }

  .p-ac-lg-stretch {
    align-content: stretch;
  }

  .p-ac-lg-between {
    align-content: space-between;
  }
}
@media screen and (min-width: 1200px) {
  .p-ac-xl-start {
    align-content: flex-start;
  }

  .p-ac-xl-end {
    align-content: flex-end;
  }

  .p-ac-xl-center {
    align-content: center;
  }

  .p-ac-xl-around {
    align-content: space-around;
  }

  .p-ac-xl-stretch {
    align-content: stretch;
  }

  .p-ac-xl-between {
    align-content: space-between;
  }
}
.p-order-0 {
  order: 0;
}

.p-order-1 {
  order: 1;
}

.p-order-2 {
  order: 2;
}

.p-order-3 {
  order: 3;
}

.p-order-4 {
  order: 4;
}

.p-order-5 {
  order: 5;
}

.p-order-6 {
  order: 6;
}

@media screen and (min-width: 576px) {
  .p-order-sm-0 {
    order: 0;
  }

  .p-order-sm-1 {
    order: 1;
  }

  .p-order-sm-2 {
    order: 2;
  }

  .p-order-sm-3 {
    order: 3;
  }

  .p-order-sm-4 {
    order: 4;
  }

  .p-order-sm-5 {
    order: 5;
  }

  .p-order-sm-6 {
    order: 6;
  }
}
@media screen and (min-width: 768px) {
  .p-order-md-0 {
    order: 0;
  }

  .p-order-md-1 {
    order: 1;
  }

  .p-order-md-2 {
    order: 2;
  }

  .p-order-md-3 {
    order: 3;
  }

  .p-order-md-4 {
    order: 4;
  }

  .p-order-md-5 {
    order: 5;
  }

  .p-order-md-6 {
    order: 6;
  }
}
@media screen and (min-width: 992px) {
  .p-order-lg-0 {
    order: 0;
  }

  .p-order-lg-1 {
    order: 1;
  }

  .p-order-lg-2 {
    order: 2;
  }

  .p-order-lg-3 {
    order: 3;
  }

  .p-order-lg-4 {
    order: 4;
  }

  .p-order-lg-5 {
    order: 5;
  }

  .p-order-lg-6 {
    order: 6;
  }
}
@media screen and (min-width: 1200px) {
  .p-order-xl-0 {
    order: 0;
  }

  .p-order-xl-1 {
    order: 1;
  }

  .p-order-xl-2 {
    order: 2;
  }

  .p-order-xl-3 {
    order: 3;
  }

  .p-order-xl-4 {
    order: 4;
  }

  .p-order-xl-5 {
    order: 5;
  }

  .p-order-xl-6 {
    order: 6;
  }
}
.p-flex-nowrap {
  flex-wrap: nowrap;
}

.p-flex-wrap {
  flex-wrap: wrap;
}

.p-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

@media screen and (min-width: 576px) {
  .p-flex-sm-nowrap {
    flex-wrap: nowrap;
  }

  .p-flex-sm-wrap {
    flex-wrap: wrap;
  }

  .p-flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}
@media screen and (min-width: 768px) {
  .p-flex-md-nowrap {
    flex-wrap: nowrap;
  }

  .p-flex-md-wrap {
    flex-wrap: wrap;
  }

  .p-flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}
@media screen and (min-width: 992px) {
  .p-flex-lg-nowrap {
    flex-wrap: nowrap;
  }

  .p-flex-lg-wrap {
    flex-wrap: wrap;
  }

  .p-flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}
@media screen and (min-width: 1200px) {
  .p-flex-xl-nowrap {
    flex-wrap: nowrap;
  }

  .p-flex-xl-wrap {
    flex-wrap: wrap;
  }

  .p-flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}
.p-pt-0 {
  padding-top: 0 !important;
}

.p-pt-1 {
  padding-top: 0.25rem !important;
}

.p-pt-2 {
  padding-top: 0.5rem !important;
}

.p-pt-3 {
  padding-top: 1rem !important;
}

.p-pt-4 {
  padding-top: 1.5rem !important;
}

.p-pt-5 {
  padding-top: 2rem !important;
}

.p-pt-6 {
  padding-top: 3rem !important;
}

.p-pr-0 {
  padding-right: 0 !important;
}

.p-pr-1 {
  padding-right: 0.25rem !important;
}

.p-pr-2 {
  padding-right: 0.5rem !important;
}

.p-pr-3 {
  padding-right: 1rem !important;
}

.p-pr-4 {
  padding-right: 1.5rem !important;
}

.p-pr-5 {
  padding-right: 2rem !important;
}

.p-pr-6 {
  padding-right: 3rem !important;
}

.p-pl-0 {
  padding-left: 0 !important;
}

.p-pl-1 {
  padding-left: 0.25rem !important;
}

.p-pl-2 {
  padding-left: 0.5rem !important;
}

.p-pl-3 {
  padding-left: 1rem !important;
}

.p-pl-4 {
  padding-left: 1.5rem !important;
}

.p-pl-5 {
  padding-left: 2rem !important;
}

.p-pl-6 {
  padding-left: 3rem !important;
}

.p-pb-0 {
  padding-bottom: 0 !important;
}

.p-pb-1 {
  padding-bottom: 0.25rem !important;
}

.p-pb-2 {
  padding-bottom: 0.5rem !important;
}

.p-pb-3 {
  padding-bottom: 1rem !important;
}

.p-pb-4 {
  padding-bottom: 1.5rem !important;
}

.p-pb-5 {
  padding-bottom: 2rem !important;
}

.p-pb-6 {
  padding-bottom: 3rem !important;
}

.p-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.p-px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.p-px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.p-px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.p-px-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.p-px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.p-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.p-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.p-py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.p-py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.p-p-0 {
  padding: 0 !important;
}

.p-p-1 {
  padding: 0.25rem !important;
}

.p-p-2 {
  padding: 0.5rem !important;
}

.p-p-3 {
  padding: 1rem !important;
}

.p-p-4 {
  padding: 1.5rem !important;
}

.p-p-5 {
  padding: 2rem !important;
}

.p-p-6 {
  padding: 3rem !important;
}

@media screen and (min-width: 576px) {
  .p-pt-sm-0 {
    padding-top: 0 !important;
  }

  .p-pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .p-pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .p-pt-sm-3 {
    padding-top: 1rem !important;
  }

  .p-pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .p-pt-sm-5 {
    padding-top: 2rem !important;
  }

  .p-pt-sm-6 {
    padding-top: 3rem !important;
  }

  .p-pr-sm-0 {
    padding-right: 0 !important;
  }

  .p-pr-sm-1 {
    padding-right: 0.25rem !important;
  }

  .p-pr-sm-2 {
    padding-right: 0.5rem !important;
  }

  .p-pr-sm-3 {
    padding-right: 1rem !important;
  }

  .p-pr-sm-4 {
    padding-right: 1.5rem !important;
  }

  .p-pr-sm-5 {
    padding-right: 2rem !important;
  }

  .p-pr-sm-6 {
    padding-right: 3rem !important;
  }

  .p-pl-sm-0 {
    padding-left: 0 !important;
  }

  .p-pl-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-pl-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-pl-sm-3 {
    padding-left: 1rem !important;
  }

  .p-pl-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-pl-sm-5 {
    padding-left: 2rem !important;
  }

  .p-pl-sm-6 {
    padding-left: 3rem !important;
  }

  .p-pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .p-pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .p-pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .p-pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .p-pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .p-pb-sm-5 {
    padding-bottom: 2rem !important;
  }

  .p-pb-sm-6 {
    padding-bottom: 3rem !important;
  }

  .p-px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .p-px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .p-px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .p-px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .p-px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .p-px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .p-px-sm-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .p-py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .p-py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .p-py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .p-py-sm-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .p-p-sm-0 {
    padding: 0 !important;
  }

  .p-p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-p-sm-3 {
    padding: 1rem !important;
  }

  .p-p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-p-sm-5 {
    padding: 2rem !important;
  }

  .p-p-sm-6 {
    padding: 3rem !important;
  }
}
@media screen and (min-width: 768px) {
  .p-pt-md-0 {
    padding-top: 0 !important;
  }

  .p-pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .p-pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .p-pt-md-3 {
    padding-top: 1rem !important;
  }

  .p-pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .p-pt-md-5 {
    padding-top: 2rem !important;
  }

  .p-pt-md-6 {
    padding-top: 3rem !important;
  }

  .p-pr-md-0 {
    padding-right: 0 !important;
  }

  .p-pr-md-1 {
    padding-right: 0.25rem !important;
  }

  .p-pr-md-2 {
    padding-right: 0.5rem !important;
  }

  .p-pr-md-3 {
    padding-right: 1rem !important;
  }

  .p-pr-md-4 {
    padding-right: 1.5rem !important;
  }

  .p-pr-md-5 {
    padding-right: 2rem !important;
  }

  .p-pr-md-6 {
    padding-right: 3rem !important;
  }

  .p-pl-md-0 {
    padding-left: 0 !important;
  }

  .p-pl-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-pl-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-pl-md-3 {
    padding-left: 1rem !important;
  }

  .p-pl-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-pl-md-5 {
    padding-left: 2rem !important;
  }

  .p-pl-md-6 {
    padding-left: 3rem !important;
  }

  .p-pb-md-0 {
    padding-bottom: 0 !important;
  }

  .p-pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .p-pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .p-pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .p-pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .p-pb-md-5 {
    padding-bottom: 2rem !important;
  }

  .p-pb-md-6 {
    padding-bottom: 3rem !important;
  }

  .p-px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .p-px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .p-px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .p-px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .p-px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .p-px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .p-px-md-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .p-py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .p-py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .p-py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .p-py-md-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .p-p-md-0 {
    padding: 0 !important;
  }

  .p-p-md-1 {
    padding: 0.25rem !important;
  }

  .p-p-md-2 {
    padding: 0.5rem !important;
  }

  .p-p-md-3 {
    padding: 1rem !important;
  }

  .p-p-md-4 {
    padding: 1.5rem !important;
  }

  .p-p-md-5 {
    padding: 2rem !important;
  }

  .p-p-md-6 {
    padding: 3rem !important;
  }
}
@media screen and (min-width: 992px) {
  .p-pt-lg-0 {
    padding-top: 0 !important;
  }

  .p-pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .p-pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .p-pt-lg-3 {
    padding-top: 1rem !important;
  }

  .p-pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .p-pt-lg-5 {
    padding-top: 2rem !important;
  }

  .p-pt-lg-6 {
    padding-top: 3rem !important;
  }

  .p-pt-lg-auto {
    padding-top: 3rem !important;
  }

  .p-pr-lg-0 {
    padding-right: 0 !important;
  }

  .p-pr-lg-1 {
    padding-right: 0.25rem !important;
  }

  .p-pr-lg-2 {
    padding-right: 0.5rem !important;
  }

  .p-pr-lg-3 {
    padding-right: 1rem !important;
  }

  .p-pr-lg-4 {
    padding-right: 1.5rem !important;
  }

  .p-pr-lg-5 {
    padding-right: 2rem !important;
  }

  .p-pr-lg-6 {
    padding-right: 3rem !important;
  }

  .p-pl-lg-0 {
    padding-left: 0 !important;
  }

  .p-pl-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-pl-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-pl-lg-3 {
    padding-left: 1rem !important;
  }

  .p-pl-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-pl-lg-5 {
    padding-left: 2rem !important;
  }

  .p-pl-lg-6 {
    padding-left: 3rem !important;
  }

  .p-pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .p-pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .p-pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .p-pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .p-pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .p-pb-lg-5 {
    padding-bottom: 2rem !important;
  }

  .p-pb-lg-6 {
    padding-bottom: 3rem !important;
  }

  .p-px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .p-px-lg-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .p-px-lg-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .p-px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .p-px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .p-px-lg-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .p-px-lg-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .p-py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .p-py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .p-py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .p-py-lg-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .p-p-lg-0 {
    padding: 0 !important;
  }

  .p-p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-p-lg-3 {
    padding: 1rem !important;
  }

  .p-p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-p-lg-5 {
    padding: 2rem !important;
  }

  .p-p-lg-6 {
    padding: 3rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .p-pt-xl-0 {
    padding-top: 0 !important;
  }

  .p-pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .p-pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .p-pt-xl-3 {
    padding-top: 1rem !important;
  }

  .p-pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .p-pt-xl-5 {
    padding-top: 2rem !important;
  }

  .p-pt-xl-6 {
    padding-top: 3rem !important;
  }

  .p-pr-xl-0 {
    padding-right: 0 !important;
  }

  .p-pr-xl-1 {
    padding-right: 0.25rem !important;
  }

  .p-pr-xl-2 {
    padding-right: 0.5rem !important;
  }

  .p-pr-xl-3 {
    padding-right: 1rem !important;
  }

  .p-pr-xl-4 {
    padding-right: 1.5rem !important;
  }

  .p-pr-xl-5 {
    padding-right: 2rem !important;
  }

  .p-pr-xl-6 {
    padding-right: 3rem !important;
  }

  .p-pl-xl-0 {
    padding-left: 0 !important;
  }

  .p-pl-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-pl-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-pl-xl-3 {
    padding-left: 1rem !important;
  }

  .p-pl-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-pl-xl-5 {
    padding-left: 2rem !important;
  }

  .p-pl-xl-6 {
    padding-left: 3rem !important;
  }

  .p-pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .p-pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .p-pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .p-pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .p-pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .p-pb-xl-5 {
    padding-bottom: 2rem !important;
  }

  .p-pb-xl-6 {
    padding-bottom: 3rem !important;
  }

  .p-px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .p-px-xl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .p-px-xl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .p-px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .p-px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .p-px-xl-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .p-px-xl-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .p-py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .p-py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .p-py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .p-py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .p-py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .p-py-xl-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .p-p-xl-0 {
    padding: 0 !important;
  }

  .p-p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-p-xl-3 {
    padding: 1rem !important;
  }

  .p-p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-p-xl-5 {
    padding: 2rem !important;
  }

  .p-p-xl-6 {
    padding: 3rem !important;
  }
}
.p-mt-0 {
  margin-top: 0 !important;
}

.p-mt-1 {
  margin-top: 0.25rem !important;
}

.p-mt-2 {
  margin-top: 0.5rem !important;
}

.p-mt-3 {
  margin-top: 1rem !important;
}

.p-mt-4 {
  margin-top: 1.5rem !important;
}

.p-mt-5 {
  margin-top: 2rem !important;
}

.p-mt-6 {
  margin-top: 3rem !important;
}

.p-mt-auto {
  margin-top: auto !important;
}

.p-mr-0 {
  margin-right: 0 !important;
}

.p-mr-1 {
  margin-right: 0.25rem !important;
}

.p-mr-2 {
  margin-right: 0.5rem !important;
}

.p-mr-3 {
  margin-right: 1rem !important;
}

.p-mr-4 {
  margin-right: 1.5rem !important;
}

.p-mr-5 {
  margin-right: 2rem !important;
}

.p-mr-6 {
  margin-right: 3rem !important;
}

.p-mr-auto {
  margin-right: auto !important;
}

.p-ml-0 {
  margin-left: 0 !important;
}

.p-ml-1 {
  margin-left: 0.25rem !important;
}

.p-ml-2 {
  margin-left: 0.5rem !important;
}

.p-ml-3 {
  margin-left: 1rem !important;
}

.p-ml-4 {
  margin-left: 1.5rem !important;
}

.p-ml-5 {
  margin-left: 2rem !important;
}

.p-ml-6 {
  margin-left: 3rem !important;
}

.p-ml-auto {
  margin-left: auto !important;
}

.p-mb-0 {
  margin-bottom: 0 !important;
}

.p-mb-1 {
  margin-bottom: 0.25rem !important;
}

.p-mb-2 {
  margin-bottom: 0.5rem !important;
}

.p-mb-3 {
  margin-bottom: 1rem !important;
}

.p-mb-4 {
  margin-bottom: 1.5rem !important;
}

.p-mb-5 {
  margin-bottom: 2rem !important;
}

.p-mb-6 {
  margin-bottom: 3rem !important;
}

.p-mb-auto {
  margin-bottom: auto !important;
}

.p-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.p-mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.p-mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.p-mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.p-mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.p-mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.p-mx-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.p-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.p-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.p-my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.p-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.p-my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.p-my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.p-my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.p-my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.p-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.p-m-0 {
  margin: 0 !important;
}

.p-m-1 {
  margin: 0.25rem !important;
}

.p-m-2 {
  margin: 0.5rem !important;
}

.p-m-3 {
  margin: 1rem !important;
}

.p-m-4 {
  margin: 1.5rem !important;
}

.p-m-5 {
  margin: 2rem !important;
}

.p-m-6 {
  margin: 3rem !important;
}

.p-m-auto {
  margin: auto !important;
}

@media screen and (min-width: 576px) {
  .p-mt-sm-0 {
    margin-top: 0 !important;
  }

  .p-mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .p-mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .p-mt-sm-3 {
    margin-top: 1rem !important;
  }

  .p-mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .p-mt-sm-5 {
    margin-top: 2rem !important;
  }

  .p-mt-sm-6 {
    margin-top: 3rem !important;
  }

  .p-mt-sm-auto {
    margin-top: 3rem !important;
  }

  .p-mr-sm-0 {
    margin-right: 0 !important;
  }

  .p-mr-sm-1 {
    margin-right: 0.25rem !important;
  }

  .p-mr-sm-2 {
    margin-right: 0.5rem !important;
  }

  .p-mr-sm-3 {
    margin-right: 1rem !important;
  }

  .p-mr-sm-4 {
    margin-right: 1.5rem !important;
  }

  .p-mr-sm-5 {
    margin-right: 2rem !important;
  }

  .p-mr-sm-6 {
    margin-right: 3rem !important;
  }

  .p-mr-sm-auto {
    margin-right: auto !important;
  }

  .p-ml-sm-0 {
    margin-left: 0 !important;
  }

  .p-ml-sm-1 {
    margin-left: 0.25rem !important;
  }

  .p-ml-sm-2 {
    margin-left: 0.5rem !important;
  }

  .p-ml-sm-3 {
    margin-left: 1rem !important;
  }

  .p-ml-sm-4 {
    margin-left: 1.5rem !important;
  }

  .p-ml-sm-5 {
    margin-left: 2rem !important;
  }

  .p-ml-sm-6 {
    margin-left: 3rem !important;
  }

  .p-ml-sm-auto {
    margin-left: auto !important;
  }

  .p-mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .p-mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .p-mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .p-mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .p-mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .p-mb-sm-5 {
    margin-bottom: 2rem !important;
  }

  .p-mb-sm-6 {
    margin-bottom: 3rem !important;
  }

  .p-mb-sm-auto {
    margin-bottom: auto !important;
  }

  .p-mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .p-mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .p-mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .p-mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .p-mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .p-mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .p-mx-sm-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .p-mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .p-my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .p-my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .p-my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .p-my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .p-my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .p-my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .p-my-sm-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .p-my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .p-m-sm-0 {
    margin: 0 !important;
  }

  .p-m-sm-1 {
    margin: 0.25rem !important;
  }

  .p-m-sm-2 {
    margin: 0.5rem !important;
  }

  .p-m-sm-3 {
    margin: 1rem !important;
  }

  .p-m-sm-4 {
    margin: 1.5rem !important;
  }

  .p-m-sm-5 {
    margin: 2rem !important;
  }

  .p-m-sm-6 {
    margin: 3rem !important;
  }

  .p-m-sm-auto {
    margin: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .p-mt-md-0 {
    margin-top: 0 !important;
  }

  .p-mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .p-mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .p-mt-md-3 {
    margin-top: 1rem !important;
  }

  .p-mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .p-mt-md-5 {
    margin-top: 2rem !important;
  }

  .p-mt-md-6 {
    margin-top: 3rem !important;
  }

  .p-mt-md-auto {
    margin-top: 3rem !important;
  }

  .p-mr-md-0 {
    margin-right: 0 !important;
  }

  .p-mr-md-1 {
    margin-right: 0.25rem !important;
  }

  .p-mr-md-2 {
    margin-right: 0.5rem !important;
  }

  .p-mr-md-3 {
    margin-right: 1rem !important;
  }

  .p-mr-md-4 {
    margin-right: 1.5rem !important;
  }

  .p-mr-md-5 {
    margin-right: 2rem !important;
  }

  .p-mr-md-6 {
    margin-right: 3rem !important;
  }

  .p-mr-md-auto {
    margin-right: auto !important;
  }

  .p-ml-md-0 {
    margin-left: 0 !important;
  }

  .p-ml-md-1 {
    margin-left: 0.25rem !important;
  }

  .p-ml-md-2 {
    margin-left: 0.5rem !important;
  }

  .p-ml-md-3 {
    margin-left: 1rem !important;
  }

  .p-ml-md-4 {
    margin-left: 1.5rem !important;
  }

  .p-ml-md-5 {
    margin-left: 2rem !important;
  }

  .p-ml-md-6 {
    margin-left: 3rem !important;
  }

  .p-ml-md-auto {
    margin-left: auto !important;
  }

  .p-mb-md-0 {
    margin-bottom: 0 !important;
  }

  .p-mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .p-mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .p-mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .p-mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .p-mb-md-5 {
    margin-bottom: 2rem !important;
  }

  .p-mb-md-6 {
    margin-bottom: 3rem !important;
  }

  .p-mb-md-auto {
    margin-bottom: auto !important;
  }

  .p-mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .p-mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .p-mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .p-mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .p-mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .p-mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .p-mx-md-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .p-mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .p-my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .p-my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .p-my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .p-my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .p-my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .p-my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .p-my-md-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .p-my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .p-m-md-0 {
    margin: 0 !important;
  }

  .p-m-md-1 {
    margin: 0.25rem !important;
  }

  .p-m-md-2 {
    margin: 0.5rem !important;
  }

  .p-m-md-3 {
    margin: 1rem !important;
  }

  .p-m-md-4 {
    margin: 1.5rem !important;
  }

  .p-m-md-5 {
    margin: 2rem !important;
  }

  .p-m-md-6 {
    margin: 3rem !important;
  }

  .p-m-md-auto {
    margin: auto !important;
  }
}
@media screen and (min-width: 992px) {
  .p-mt-lg-0 {
    margin-top: 0 !important;
  }

  .p-mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .p-mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .p-mt-lg-3 {
    margin-top: 1rem !important;
  }

  .p-mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .p-mt-lg-5 {
    margin-top: 2rem !important;
  }

  .p-mt-lg-6 {
    margin-top: 3rem !important;
  }

  .p-mt-lg-auto {
    margin-top: 3rem !important;
  }

  .p-mr-lg-0 {
    margin-right: 0 !important;
  }

  .p-mr-lg-1 {
    margin-right: 0.25rem !important;
  }

  .p-mr-lg-2 {
    margin-right: 0.5rem !important;
  }

  .p-mr-lg-3 {
    margin-right: 1rem !important;
  }

  .p-mr-lg-4 {
    margin-right: 1.5rem !important;
  }

  .p-mr-lg-5 {
    margin-right: 2rem !important;
  }

  .p-mr-lg-6 {
    margin-right: 3rem !important;
  }

  .p-mr-lg-auto {
    margin-right: auto !important;
  }

  .p-ml-lg-0 {
    margin-left: 0 !important;
  }

  .p-ml-lg-1 {
    margin-left: 0.25rem !important;
  }

  .p-ml-lg-2 {
    margin-left: 0.5rem !important;
  }

  .p-ml-lg-3 {
    margin-left: 1rem !important;
  }

  .p-ml-lg-4 {
    margin-left: 1.5rem !important;
  }

  .p-ml-lg-5 {
    margin-left: 2rem !important;
  }

  .p-ml-lg-6 {
    margin-left: 3rem !important;
  }

  .p-ml-lg-auto {
    margin-left: auto !important;
  }

  .p-mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .p-mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .p-mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .p-mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .p-mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .p-mb-lg-5 {
    margin-bottom: 2rem !important;
  }

  .p-mb-lg-6 {
    margin-bottom: 3rem !important;
  }

  .p-mb-lg-auto {
    margin-bottom: auto !important;
  }

  .p-mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .p-mx-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .p-mx-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .p-mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .p-mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .p-mx-lg-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .p-mx-lg-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .p-mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .p-my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .p-my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .p-my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .p-my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .p-my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .p-my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .p-my-lg-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .p-my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .p-m-lg-0 {
    margin: 0 !important;
  }

  .p-m-lg-1 {
    margin: 0.25rem !important;
  }

  .p-m-lg-2 {
    margin: 0.5rem !important;
  }

  .p-m-lg-3 {
    margin: 1rem !important;
  }

  .p-m-lg-4 {
    margin: 1.5rem !important;
  }

  .p-m-lg-5 {
    margin: 2rem !important;
  }

  .p-m-lg-6 {
    margin: 3rem !important;
  }

  .p-m-lg-auto {
    margin: auto !important;
  }
}
@media screen and (min-width: 1200px) {
  .p-mt-xl-0 {
    margin-top: 0 !important;
  }

  .p-mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .p-mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .p-mt-xl-3 {
    margin-top: 1rem !important;
  }

  .p-mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .p-mt-xl-5 {
    margin-top: 2rem !important;
  }

  .p-mt-xl-6 {
    margin-top: 3rem !important;
  }

  .p-mt-xl-auto {
    margin-top: 3rem !important;
  }

  .p-mr-xl-0 {
    margin-right: 0 !important;
  }

  .p-mr-xl-1 {
    margin-right: 0.25rem !important;
  }

  .p-mr-xl-2 {
    margin-right: 0.5rem !important;
  }

  .p-mr-xl-3 {
    margin-right: 1rem !important;
  }

  .p-mr-xl-4 {
    margin-right: 1.5rem !important;
  }

  .p-mr-xl-5 {
    margin-right: 2rem !important;
  }

  .p-mr-xl-6 {
    margin-right: 3rem !important;
  }

  .p-mr-xl-auto {
    margin-right: auto !important;
  }

  .p-ml-xl-0 {
    margin-left: 0 !important;
  }

  .p-ml-xl-1 {
    margin-left: 0.25rem !important;
  }

  .p-ml-xl-2 {
    margin-left: 0.5rem !important;
  }

  .p-ml-xl-3 {
    margin-left: 1rem !important;
  }

  .p-ml-xl-4 {
    margin-left: 1.5rem !important;
  }

  .p-ml-xl-5 {
    margin-left: 2rem !important;
  }

  .p-ml-xl-6 {
    margin-left: 3rem !important;
  }

  .p-ml-xl-auto {
    margin-left: auto !important;
  }

  .p-mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .p-mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .p-mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .p-mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .p-mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .p-mb-xl-5 {
    margin-bottom: 2rem !important;
  }

  .p-mb-xl-6 {
    margin-bottom: 3rem !important;
  }

  .p-mb-xl-auto {
    margin-bottom: auto !important;
  }

  .p-mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .p-mx-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .p-mx-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .p-mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .p-mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .p-mx-xl-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .p-mx-xl-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .p-mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .p-my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .p-my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .p-my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .p-my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .p-my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .p-my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .p-my-xl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .p-my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .p-m-xl-0 {
    margin: 0 !important;
  }

  .p-m-xl-1 {
    margin: 0.25rem !important;
  }

  .p-m-xl-2 {
    margin: 0.5rem !important;
  }

  .p-m-xl-3 {
    margin: 1rem !important;
  }

  .p-m-xl-4 {
    margin: 1.5rem !important;
  }

  .p-m-xl-5 {
    margin: 2rem !important;
  }

  .p-m-xl-6 {
    margin: 3rem !important;
  }

  .p-m-xl-auto {
    margin: auto !important;
  }
}
.p-shadow-1 {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.p-shadow-2 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-shadow-3 {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.p-shadow-4 {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-shadow-5 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
}

.p-shadow-6 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.p-shadow-7 {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.p-shadow-8 {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.p-shadow-9 {
  box-shadow: 0 5px 6px -3px rgba(0, 0, 0, 0.2), 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.p-shadow-10 {
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.p-shadow-11 {
  box-shadow: 0 6px 7px -4px rgba(0, 0, 0, 0.2), 0 11px 15px 1px rgba(0, 0, 0, 0.14), 0 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.p-shadow-12 {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.p-shadow-13 {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.p-shadow-14 {
  box-shadow: 0 7px 9px -4px rgba(0, 0, 0, 0.2), 0 14px 21px 2px rgba(0, 0, 0, 0.14), 0 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.p-shadow-15 {
  box-shadow: 0 8px 9px -5px rgba(0, 0, 0, 0.2), 0 15px 22px 2px rgba(0, 0, 0, 0.14), 0 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.p-shadow-16 {
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.p-shadow-17 {
  box-shadow: 0 8px 11px -5px rgba(0, 0, 0, 0.2), 0 17px 26px 2px rgba(0, 0, 0, 0.14), 0 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.p-shadow-18 {
  box-shadow: 0 9px 11px -5px rgba(0, 0, 0, 0.2), 0 18px 28px 2px rgba(0, 0, 0, 0.14), 0 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.p-shadow-19 {
  box-shadow: 0 9px 12px -6px rgba(0, 0, 0, 0.2), 0 19px 29px 2px rgba(0, 0, 0, 0.14), 0 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.p-shadow-20 {
  box-shadow: 0 10px 13px -6px rgba(0, 0, 0, 0.2), 0 20px 31px 3px rgba(0, 0, 0, 0.14), 0 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.p-shadow-21 {
  box-shadow: 0 10px 13px -6px rgba(0, 0, 0, 0.2), 0 21px 33px 3px rgba(0, 0, 0, 0.14), 0 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.p-shadow-22 {
  box-shadow: 0 10px 14px -6px rgba(0, 0, 0, 0.2), 0 22px 35px 3px rgba(0, 0, 0, 0.14), 0 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.p-shadow-23 {
  box-shadow: 0 11px 14px -7px rgba(0, 0, 0, 0.2), 0 23px 36px 3px rgba(0, 0, 0, 0.14), 0 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.p-shadow-24 {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

:root {
  --surface-a:#ffffff;
  --surface-b:#f8f9fa;
  --surface-c:#e9ecef;
  --surface-d:#dee2e6;
  --surface-e:#ffffff;
  --surface-f:#ffffff;
  --text-color:#495057;
  --text-color-secondary:#6c757d;
  --primary-color:#493064;
  --primary-color-text:#ffffff;
  --font-family:Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

/* Add your customizations of the theme variables here */
* {
  box-sizing: border-box;
}

.p-component {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: normal;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
}

.p-disabled, .p-component:disabled {
  opacity: 0.6;
}

.p-error {
  color: #f44336;
}

.p-text-secondary {
  color: #6c757d;
}

.pi {
  font-size: 1rem;
}

.p-link {
  font-size: 1rem;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  border-radius: 4px;
}
.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}

.p-autocomplete .p-autocomplete-loader {
  right: 0.5rem;
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 2.857rem;
}
.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.25rem 0.5rem;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #493064;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
  border-color: #493064;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.25rem 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  color: #495057;
  padding: 0;
  margin: 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  background: #E3F2FD;
  color: #495057;
  border-radius: 4px;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: #f44336;
}

.p-autocomplete-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.5rem 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #495057;
  background: #e9ecef;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #f44336;
}

.p-datepicker {
  padding: 0.5rem;
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}
.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid #dee2e6;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
  border-color: #493064;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 1rem;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.5rem;
}
.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}
.p-datepicker table td {
  padding: 0.5rem;
}
.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: box-shadow 0.2s;
  border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: #495057;
  background: #E3F2FD;
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-datepicker table td.p-datepicker-today > span {
  background: #ced4da;
  color: #495057;
  border-color: transparent;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #495057;
  background: #E3F2FD;
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #dee2e6;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #dee2e6;
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #495057;
  background: #E3F2FD;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid #dee2e6;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ecef;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ecef;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th, .p-datepicker table td {
    padding: 0;
  }
}
.p-cascadeselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 4px;
}
.p-cascadeselect:not(.p-disabled):hover {
  border-color: #493064;
}
.p-cascadeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
  border-color: #493064;
}
.p-cascadeselect .p-cascadeselect-label {
  background: transparent;
  border: 0 none;
  padding: 0.5rem 0.5rem;
}
.p-cascadeselect .p-cascadeselect-label.p-placeholder {
  color: #6c757d;
}
.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-cascadeselect .p-cascadeselect-trigger {
  background: transparent;
  color: #6c757d;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-cascadeselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  min-width: 12.5rem;
}
.p-cascadeselect-panel .p-cascadeselect-items {
  padding: 0.5rem 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  margin: 0;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
  padding: 0.5rem 1rem;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #b69dd0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
  font-size: 0.875rem;
}

p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
  border-color: #f44336;
}

.p-input-filled .p-cascadeselect {
  background: #f8f9fa;
}
.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

.p-checkbox {
  width: 20px;
  height: 20px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #495057;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #493064;
  background: #493064;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #493064;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
  border-color: #493064;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #3a2650;
  background: #3a2650;
  color: #ffffff;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #f44336;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #f8f9fa;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #493064;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #3a2650;
}

.p-checkbox-label {
  margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container {
  padding: 0.25rem 0.5rem;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #493064;
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
  border-color: #493064;
}
.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  background: #E3F2FD;
  color: #495057;
  border-radius: 4px;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.25rem 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  color: #495057;
  padding: 0;
  margin: 0;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: #f44336;
}

.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #323232;
  border-color: #191919;
}
.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #ffffff;
}

.p-colorpicker-overlay-panel {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-dropdown {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 4px;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #493064;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
  border-color: #493064;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.5rem;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
}
.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #6c757d;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-dropdown .p-dropdown-clear-icon {
  color: #6c757d;
  right: 2.357rem;
}

.p-dropdown-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-dropdown-panel .p-dropdown-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.5rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 0.5rem 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
  background: transparent;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #f44336;
}

.p-input-filled .p-dropdown {
  background: #f8f9fa;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

.p-editor-container .p-editor-toolbar {
  background: #f8f9fa;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 0.5rem 0;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #495057;
  background: #e9ecef;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: 0.5rem 1rem;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-content .ql-editor {
  background: #ffffff;
  color: #495057;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #493064;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #493064;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #493064;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #493064;
}

.p-inputgroup-addon {
  background: #e9ecef;
  color: #6c757d;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  padding: 0.5rem 0.5rem;
  min-width: 2.357rem;
}
.p-inputgroup-addon:last-child {
  border-right: 1px solid #ced4da;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.357rem;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: #f44336;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: #f44336;
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}
.p-inputswitch .p-inputswitch-slider {
  background: #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 30px;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #b6bfc8;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #493064;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #422b5a;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: #f44336;
}

.p-inputtext {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.5rem 0.5rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 4px;
}
.p-inputtext:enabled:hover {
  border-color: #493064;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
  border-color: #493064;
}
.p-inputtext.ng-dirty.ng-invalid {
  border-color: #f44336;
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.4375rem;
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.625rem 0.625rem;
}

.p-float-label > label {
  left: 0.5rem;
  color: #6c757d;
  transition-duration: 0.2s;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: #f44336;
}

.p-input-icon-left > i:first-of-type {
  left: 0.5rem;
  color: #6c757d;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2rem;
}

.p-input-icon-left.p-float-label > label {
  left: 2rem;
}

.p-input-icon-right > i:last-of-type {
  right: 0.5rem;
  color: #6c757d;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 2rem;
}

::-webkit-input-placeholder {
  color: #6c757d;
}

:-moz-placeholder {
  color: #6c757d;
}

::-moz-placeholder {
  color: #6c757d;
}

:-ms-input-placeholder {
  color: #6c757d;
}

.p-input-filled .p-inputtext {
  background-color: #f8f9fa;
}
.p-input-filled .p-inputtext:enabled:hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-inputtext:enabled:focus {
  background-color: #ffffff;
}

.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.4375rem 0.4375rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.625rem 0.625rem;
}

.p-listbox {
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
.p-listbox .p-listbox-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 1.5rem;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}
.p-listbox .p-listbox-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox .p-listbox-list {
  padding: 0.5rem 0;
}
.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #b69dd0;
}
.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox .p-listbox-list .p-listbox-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: #f44336;
}

.p-multiselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 4px;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: #493064;
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
  border-color: #493064;
}
.p-multiselect .p-multiselect-label {
  padding: 0.5rem 0.5rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  background: #E3F2FD;
  color: #495057;
  border-radius: 4px;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #6c757d;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.25rem 0.5rem;
}

.p-multiselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 1.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-multiselect-panel .p-multiselect-items {
  padding: 0.5rem 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #b69dd0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
  background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #f44336;
}

.p-input-filled .p-multiselect {
  background: #f8f9fa;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

.p-password-panel {
  padding: 1rem;
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
}

.p-radiobutton {
  width: 20px;
  height: 20px;
}
.p-radiobutton .p-radiobutton-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #495057;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #493064;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
  border-color: #493064;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  background-color: #ffffff;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #493064;
  background: #493064;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #3a2650;
  background: #3a2650;
  color: #ffffff;
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: #f44336;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #f8f9fa;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: #493064;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #3a2650;
}

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.p-rating .p-rating-icon {
  color: #495057;
  margin-left: 0.5rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  font-size: 1.143rem;
}
.p-rating .p-rating-icon.p-rating-cancel {
  color: #e74c3c;
}
.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}
.p-rating .p-rating-icon.pi-star {
  color: #493064;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #493064;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #c0392b;
}

.p-selectbutton .p-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #6c757d;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #6c757d;
}
.p-selectbutton .p-button.p-highlight {
  background: #493064;
  border-color: #493064;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover {
  background: #422b5a;
  border-color: #422b5a;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: #f44336;
}

.p-slider {
  background: #dee2e6;
  border: 0 none;
  border-radius: 4px;
}
.p-slider.p-slider-horizontal {
  height: 0.286rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
  width: 0.286rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: #ffffff;
  border: 2px solid #493064;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-slider .p-slider-range {
  background: #493064;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #493064;
  border-color: #493064;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.2s;
}

.p-togglebutton.p-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: #6c757d;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #6c757d;
}
.p-togglebutton.p-button.p-highlight {
  background: #493064;
  border-color: #493064;
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover {
  background: #422b5a;
  border-color: #422b5a;
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: #f44336;
}

.p-button {
  color: #ffffff;
  background: #493064;
  border: 1px solid #493064;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 4px;
}
.p-button:enabled:hover {
  background: #422b5a;
  color: #ffffff;
  border-color: #422b5a;
}
.p-button:enabled:active {
  background: #3a2650;
  color: #ffffff;
  border-color: #3a2650;
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: #493064;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover {
  background: rgba(73, 48, 100, 0.04);
  color: #493064;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:active {
  background: rgba(73, 48, 100, 0.16);
  color: #493064;
  border: 1px solid;
}
.p-button.p-button-outlined.p-button-plain {
  color: #6c757d;
  border-color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}
.p-button.p-button-text {
  background-color: transparent;
  color: #493064;
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover {
  background: rgba(73, 48, 100, 0.04);
  color: #493064;
  border-color: transparent;
}
.p-button.p-button-text:enabled:active {
  background: rgba(73, 48, 100, 0.16);
  color: #493064;
  border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: #493064;
  background-color: #ffffff;
}
.p-button.p-button-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 2.357rem;
  padding: 0.5rem 0;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.357rem;
}
.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.875rem;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}
.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.625rem 1.25rem;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}

.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 2.357rem;
}
.p-fluid .p-buttonset {
  display: flex;
}
.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  color: #ffffff;
  background: #607D8B;
  border: 1px solid #607D8B;
}
.p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: #56717d;
  color: #ffffff;
  border-color: #56717d;
}
.p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #beccd2;
}
.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: #4d646f;
  color: #ffffff;
  border-color: #4d646f;
}
.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #607D8B;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(96, 125, 139, 0.04);
  color: #607D8B;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(96, 125, 139, 0.16);
  color: #607D8B;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: #607D8B;
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(96, 125, 139, 0.04);
  border-color: transparent;
  color: #607D8B;
}
.p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: rgba(96, 125, 139, 0.16);
  border-color: transparent;
  color: #607D8B;
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
  color: #ffffff;
  background: #0288D1;
  border: 1px solid #0288D1;
}
.p-button.p-button-info:enabled:hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: #027abc;
  color: #ffffff;
  border-color: #027abc;
}
.p-button.p-button-info:enabled:focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #89d4fe;
}
.p-button.p-button-info:enabled:active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:enabled:active {
  background: #026da7;
  color: #ffffff;
  border-color: #026da7;
}
.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: transparent;
  color: #0288D1;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
  background: rgba(2, 136, 209, 0.04);
  color: #0288D1;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(2, 136, 209, 0.16);
  color: #0288D1;
  border: 1px solid;
}
.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: transparent;
  color: #0288D1;
  border-color: transparent;
}
.p-button.p-button-info.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
  background: rgba(2, 136, 209, 0.04);
  border-color: transparent;
  color: #0288D1;
}
.p-button.p-button-info.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
  background: rgba(2, 136, 209, 0.16);
  border-color: transparent;
  color: #0288D1;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  color: #ffffff;
  background: #689F38;
  border: 1px solid #689F38;
}
.p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: #5e8f32;
  color: #ffffff;
  border-color: #5e8f32;
}
.p-button.p-button-success:enabled:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #c2e0a8;
}
.p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
  background: #537f2d;
  color: #ffffff;
  border-color: #537f2d;
}
.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: #689F38;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: rgba(104, 159, 56, 0.04);
  color: #689F38;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(104, 159, 56, 0.16);
  color: #689F38;
  border: 1px solid;
}
.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: #689F38;
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: rgba(104, 159, 56, 0.04);
  border-color: transparent;
  color: #689F38;
}
.p-button.p-button-success.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: rgba(104, 159, 56, 0.16);
  border-color: transparent;
  color: #689F38;
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
  color: #212529;
  background: #FBC02D;
  border: 1px solid #FBC02D;
}
.p-button.p-button-warning:enabled:hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: #fab710;
  color: #212529;
  border-color: #fab710;
}
.p-button.p-button-warning:enabled:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #fde6ab;
}
.p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: #e8a704;
  color: #212529;
  border-color: #e8a704;
}
.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: #FBC02D;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: rgba(251, 192, 45, 0.04);
  color: #FBC02D;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(251, 192, 45, 0.16);
  color: #FBC02D;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: #FBC02D;
  border-color: transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: rgba(251, 192, 45, 0.04);
  border-color: transparent;
  color: #FBC02D;
}
.p-button.p-button-warning.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: rgba(251, 192, 45, 0.16);
  border-color: transparent;
  color: #FBC02D;
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
  color: #ffffff;
  background: #9C27B0;
  border: 1px solid #9C27B0;
}
.p-button.p-button-help:enabled:hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:enabled:hover {
  background: #8c239e;
  color: #ffffff;
  border-color: #8c239e;
}
.p-button.p-button-help:enabled:focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #df9eea;
}
.p-button.p-button-help:enabled:active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:enabled:active {
  background: #7d1f8d;
  color: #ffffff;
  border-color: #7d1f8d;
}
.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: transparent;
  color: #9C27B0;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
  background: rgba(156, 39, 176, 0.04);
  color: #9C27B0;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(156, 39, 176, 0.16);
  color: #9C27B0;
  border: 1px solid;
}
.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: transparent;
  color: #9C27B0;
  border-color: transparent;
}
.p-button.p-button-help.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
  background: rgba(156, 39, 176, 0.04);
  border-color: transparent;
  color: #9C27B0;
}
.p-button.p-button-help.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
  background: rgba(156, 39, 176, 0.16);
  border-color: transparent;
  color: #9C27B0;
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
  color: #ffffff;
  background: #D32F2F;
  border: 1px solid #D32F2F;
}
.p-button.p-button-danger:enabled:hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: #c02929;
  color: #ffffff;
  border-color: #c02929;
}
.p-button.p-button-danger:enabled:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #edacac;
}
.p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: #aa2424;
  color: #ffffff;
  border-color: #aa2424;
}
.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: #D32F2F;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: rgba(211, 47, 47, 0.04);
  color: #D32F2F;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(211, 47, 47, 0.16);
  color: #D32F2F;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: #D32F2F;
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: rgba(211, 47, 47, 0.04);
  border-color: transparent;
  color: #D32F2F;
}
.p-button.p-button-danger.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: rgba(211, 47, 47, 0.16);
  border-color: transparent;
  color: #D32F2F;
}

.p-button.p-button-link {
  color: #3a2650;
  background: transparent;
  border: transparent;
}
.p-button.p-button-link:enabled:hover {
  background: transparent;
  color: #3a2650;
  border-color: transparent;
}
.p-button.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}
.p-button.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: 0 0 0 0.2rem #b69dd0;
  border-color: transparent;
}
.p-button.p-button-link:enabled:active {
  background: transparent;
  color: #3a2650;
  border-color: transparent;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-carousel .p-carousel-indicators {
  padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #e9ecef;
  width: 2rem;
  height: 0.5rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #dee2e6;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #E3F2FD;
  color: #495057;
}

.p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #495057;
  background: #E3F2FD;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #6c757d;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: #493064;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #493064;
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: #e9ecef;
  color: #493064;
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: #493064;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #b69dd0;
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #b69dd0;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #E3F2FD;
  color: #495057;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #E3F2FD;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #E3F2FD;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-datatable .p-column-resizer-helper {
  background: #493064;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #f8f9fa;
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #fcfcfc;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #E3F2FD;
  color: #495057;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: #495057;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: #495057;
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}

.p-dataview .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-dataview-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-dataview .p-dataview-content {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid #e9ecef;
  border-width: 0 0 1px 0;
}
.p-dataview .p-dataview-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}
.p-dataview .p-dataview-emptymessage {
  padding: 1rem;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-column-filter-menu-button:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: #e9ecef;
  color: #495057;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: #E3F2FD;
  color: #495057;
}
.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-column-filter-clear-button:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}

.p-column-filter-overlay {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  min-width: 12.5rem;
}
.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0.5rem 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #b69dd0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
  margin-top: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}
.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.5rem 1rem;
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1rem;
}

.fc .fc-view-container th {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #495057;
}
.fc .fc-view-container td.fc-widget-content {
  background: #ffffff;
  border: 1px solid #dee2e6;
  color: #495057;
}
.fc .fc-view-container td.fc-head-container {
  border: 1px solid #dee2e6;
}
.fc .fc-view-container .fc-row {
  border-right: 1px solid #dee2e6;
}
.fc .fc-view-container .fc-event {
  background: #422b5a;
  border: 1px solid #422b5a;
  color: #ffffff;
}
.fc .fc-view-container .fc-divider {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
}
.fc .fc-toolbar .fc-button {
  color: #ffffff;
  background: #493064;
  border: 1px solid #493064;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.fc .fc-toolbar .fc-button:enabled:hover {
  background: #422b5a;
  color: #ffffff;
  border-color: #422b5a;
}
.fc .fc-toolbar .fc-button:enabled:active {
  background: #3a2650;
  color: #ffffff;
  border-color: #3a2650;
}
.fc .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "";
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "";
}
.fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #493064;
  border-color: #493064;
  color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #422b5a;
  border-color: #422b5a;
  color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
  z-index: 1;
}
.fc .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.fc .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-orderlist .p-orderlist-controls {
  padding: 1rem;
}
.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}
.p-orderlist .p-orderlist-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-orderlist .p-orderlist-header .p-orderlist-title {
  font-weight: 600;
}
.p-orderlist .p-orderlist-filter-container {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 1.5rem;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}
.p-orderlist .p-orderlist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 0.5rem 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.5rem 1rem;
  margin: 0;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: transform 0.2s, box-shadow 0.2s;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #b69dd0;
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
}
.p-orderlist .p-orderlist-list .p-orderlist-droppoint.p-orderlist-droppoint-highlight {
  background-color: #89c8f7;
}

@media screen and (max-width: 769px) {
  .p-orderlist {
    flex-direction: column;
  }
  .p-orderlist .p-orderlist-controls {
    padding: 1rem;
    flex-direction: row;
  }
  .p-orderlist .p-orderlist-controls .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .p-orderlist .p-orderlist-controls .p-button:last-child {
    margin-right: 0;
  }
}
.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: #E3F2FD;
  color: #495057;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #6cbbf5;
}
.p-organizationchart .p-organizationchart-line-down {
  background: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #dee2e6;
  border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #dee2e6;
  border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 1rem;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}

.p-paginator {
  background: #ffffff;
  color: #6c757d;
  border: solid #e9ecef;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: transparent;
  color: #495057;
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  height: 2.357rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #E3F2FD;
  border-color: #E3F2FD;
  color: #495057;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #e9ecef;
  border-color: transparent;
  color: #495057;
}

.p-picklist .p-picklist-buttons {
  padding: 1rem;
}
.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-picklist .p-picklist-header .p-picklist-title {
  font-weight: 600;
}
.p-picklist .p-picklist-filter-container {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 1.5rem;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}
.p-picklist .p-picklist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 0.5rem 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.5rem 1rem;
  margin: 0;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: transform 0.2s, box-shadow 0.2s;
}
.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #b69dd0;
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #495057;
  background: #E3F2FD;
}
.p-picklist .p-picklist-list .p-picklist-droppoint.p-picklist-droppoint-highlight {
  background-color: #89c8f7;
}
.p-picklist .p-picklist-list .p-picklist-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
}

@media screen and (max-width: 769px) {
  .p-picklist {
    flex-direction: column;
  }
  .p-picklist .p-picklist-buttons {
    padding: 1rem;
    flex-direction: row;
  }
  .p-picklist .p-picklist-buttons .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .p-picklist .p-picklist-buttons .p-button:last-child {
    margin-right: 0;
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
    content: "";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
    content: "";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
    content: "";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
    content: "";
  }
}
.p-timeline .p-timeline-event-marker {
  border: 2px solid #493064;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: #ffffff;
}
.p-timeline .p-timeline-event-connector {
  background-color: #dee2e6;
}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}
.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}

.p-tree {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 1rem;
  border-radius: 4px;
}
.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 4px;
  transition: box-shadow 0.2s;
  padding: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: #6c757d;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #495057;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #E3F2FD;
  color: #495057;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #495057;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: #495057;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: #e9ecef;
  color: #495057;
}
.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}
.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}
.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}
.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background-color: #89c8f7;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border-radius: 4px;
  border: 1px solid #dee2e6;
  background-color: #ffffff;
  color: #495057;
  padding: 0.5rem;
  transition: box-shadow 0.2s;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background-color: #E3F2FD;
  color: #495057;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #495057;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}

.p-treetable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-treetable-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}
.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
}
.p-treetable .p-sortable-column {
  outline-color: #b69dd0;
}
.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #495057;
  background: #E3F2FD;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #6c757d;
}
.p-treetable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: #493064;
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #493064;
}
.p-treetable .p-treetable-tbody > tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #b69dd0;
}
.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #495057;
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: #E3F2FD;
  color: #495057;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #495057;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #495057;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
  color: #495057;
}
.p-treetable .p-column-resizer-helper {
  background: #493064;
}
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #f8f9fa;
}
.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}
.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem;
}
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}

.p-virtualscroller .p-virtualscroller-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-virtualscroller .p-virtualscroller-content {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  padding: 0;
}
.p-virtualscroller .p-virtualscroller-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: 4px;
  transition: box-shadow 0.2s;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #495057;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #f8f9fa;
  border-color: #dee2e6;
  color: #495057;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: #dee2e6;
  background: #e9ecef;
  color: #495057;
}
.p-accordion .p-accordion-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0;
}
.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}
.p-accordion p-accordiontab .p-accordion-content {
  border-radius: 0;
}
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-accordion p-accordiontab:last-child .p-accordion-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-card {
  background: #ffffff;
  color: #495057;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.p-card .p-card-body {
  padding: 1rem;
}
.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #6c757d;
}
.p-card .p-card-content {
  padding: 1rem 0;
}
.p-card .p-card-footer {
  padding: 1rem 0 0 0;
}

.p-divider .p-divider-content {
  background-color: #ffffff;
}
.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
}
.p-divider.p-divider-horizontal:before {
  border-top: 1px #dee2e6;
}
.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}
.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
}
.p-divider.p-divider-vertical:before {
  border-left: 1px #dee2e6;
}
.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}

.p-fieldset {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-radius: 4px;
}
.p-fieldset .p-fieldset-legend {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: 4px;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 1rem;
  color: #495057;
  border-radius: 4px;
  transition: box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #495057;
}
.p-fieldset .p-fieldset-content {
  padding: 1rem;
}

.p-panel .p-panel-header {
  border: 1px solid #dee2e6;
  padding: 1rem;
  background: #f8f9fa;
  color: #495057;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-panel .p-panel-header .p-panel-title {
  font-weight: 600;
}
.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.5rem 1rem;
}
.p-panel .p-panel-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 0 none;
}
.p-panel .p-panel-footer {
  padding: 0.5rem 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-top: 0 none;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: #f8f9fa;
  border: 0 none;
}

.p-splitter {
  border: 1px solid #dee2e6;
  background: #ffffff;
  border-radius: 4px;
  color: #495057;
}
.p-splitter .p-splitter-gutter {
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  background: #f8f9fa;
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: #dee2e6;
}
.p-splitter .p-splitter-gutter-resizing {
  background: #dee2e6;
}

.p-tabview .p-tabview-nav {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #dee2e6 transparent;
  background: #ffffff;
  color: #6c757d;
  padding: 1rem;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #ffffff;
  border-color: #6c757d;
  color: #6c757d;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #493064;
  color: #493064;
}
.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}
.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 1rem;
  border: 0 none;
  color: #495057;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-toolbar {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-radius: 4px;
}
.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}

.p-confirm-popup {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.p-confirm-popup .p-confirm-popup-content {
  padding: 1rem;
}
.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0.5rem 1rem;
}
.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}
.p-confirm-popup:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-confirm-popup:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: #ffffff;
}
.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: #ffffff;
}
.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}

.p-dialog {
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: 0 none;
}
.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 1.5rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600;
  font-size: 1.25rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 2rem 1.5rem;
}
.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 1.5rem 1.5rem;
  text-align: right;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.p-overlaypanel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 1rem;
}
.p-overlaypanel .p-overlaypanel-close {
  background: #493064;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #422b5a;
  color: #ffffff;
}
.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #ffffff;
}

.p-sidebar {
  background: #ffffff;
  color: #495057;
  padding: 1rem;
  border: 0 none;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.p-sidebar .p-sidebar-close {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-sidebar .p-sidebar-close:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-sidebar .p-sidebar-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}

.p-sidebar-mask.p-component-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.p-tooltip .p-tooltip-text {
  background: #495057;
  color: #ffffff;
  padding: 0.5rem 0.5rem;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #495057;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #495057;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #495057;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #495057;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #f8f9fa;
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}
.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 2rem 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-fileupload .p-progressbar {
  height: 0.25rem;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1rem;
}
.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: #422b5a;
  color: #ffffff;
  border-color: #422b5a;
}
.p-fileupload-choose:not(.p-disabled):active {
  background: #3a2650;
  color: #ffffff;
  border-color: #3a2650;
}

.p-breadcrumb {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 1rem;
}
.p-breadcrumb ul li .p-menuitem-link {
  transition: box-shadow 0.2s;
  border-radius: 4px;
}
.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: #495057;
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #495057;
}
.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #6c757d;
}

.p-contextmenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 12.5rem;
}
.p-contextmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #b69dd0;
}
.p-contextmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-contextmenu .p-menuitem {
  margin: 0;
}
.p-contextmenu .p-menuitem:last-child {
  margin: 0;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-contextmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-megamenu {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 4px;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #b69dd0;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-megamenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #b69dd0;
}
.p-megamenu .p-megamenu-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-megamenu .p-megamenu-submenu {
  padding: 0.25rem 0;
  width: 12.5rem;
}
.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-megamenu .p-megamenu-submenu .p-menuitem {
  margin: 0;
}
.p-megamenu .p-megamenu-submenu .p-menuitem:last-child {
  margin: 0;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.25rem 0;
}
.p-megamenu.p-megamenu-vertical .p-menuitem {
  margin: 0;
}
.p-megamenu.p-megamenu-vertical .p-menuitem:last-child {
  margin: 0;
}

.p-menu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  width: 12.5rem;
}
.p-menu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #b69dd0;
}
.p-menu.p-menu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-menu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-menu .p-menuitem {
  margin: 0;
}
.p-menu .p-menuitem:last-child {
  margin: 0;
}

.p-menubar {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}
.p-menubar .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-menubar .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #b69dd0;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 4px;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #b69dd0;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-menubar .p-submenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 12.5rem;
}
.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}
.p-menubar .p-submenu-list .p-menuitem {
  margin: 0;
}
.p-menubar .p-submenu-list .p-menuitem:last-child {
  margin: 0;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-menubar .p-menubar-button:hover {
    color: #6c757d;
    background: #e9ecef;
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #b69dd0;
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #495057;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #6c757d;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #495057;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #6c757d;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #6c757d;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #b69dd0;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 2.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 3.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 6.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 8.25rem;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: 4px;
  transition: box-shadow 0.2s;
}
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #495057;
}
.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: #f8f9fa;
  border-color: #dee2e6;
  color: #495057;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: #dee2e6;
  background: #e9ecef;
  color: #495057;
}
.p-panelmenu .p-panelmenu-content {
  padding: 0.25rem 0;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #b69dd0;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}
.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-slidemenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  width: 12.5rem;
}
.p-slidemenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #b69dd0;
}
.p-slidemenu.p-slidemenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-slidemenu .p-slidemenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: #e9ecef;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: #495057;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: #6c757d;
}
.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}
.p-slidemenu .p-slidemenu-backward {
  padding: 0.75rem 1rem;
  color: #495057;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 4px;
  background: #ffffff;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #495057;
  border: 1px solid #e9ecef;
  background: #ffffff;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #6c757d;
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #E3F2FD;
  color: #495057;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #495057;
}
.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid #dee2e6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #dee2e6 transparent;
  background: #ffffff;
  color: #6c757d;
  padding: 1rem;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: #ffffff;
  border-color: #6c757d;
  color: #6c757d;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: #493064;
  color: #493064;
}

.p-tieredmenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  width: 12.5rem;
}
.p-tieredmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}
.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #b69dd0;
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-tieredmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-tieredmenu .p-menuitem {
  margin: 0;
}
.p-tieredmenu .p-menuitem:last-child {
  margin: 0;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #6c757d;
}
.p-tieredmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-inline-message {
  padding: 0.5rem 0.5rem;
  margin: 0;
  border-radius: 4px;
}
.p-inline-message.p-inline-message-info {
  background: #B3E5FC;
  border: solid #0891cf;
  border-width: 0px;
  color: #044868;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #044868;
}
.p-inline-message.p-inline-message-success {
  background: #C8E6C9;
  border: solid #439446;
  border-width: 0px;
  color: #224a23;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #224a23;
}
.p-inline-message.p-inline-message-warn {
  background: #FFECB3;
  border: solid #d9a300;
  border-width: 0px;
  color: #6d5100;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #6d5100;
}
.p-inline-message.p-inline-message-error {
  background: #FFCDD2;
  border: solid #e60017;
  border-width: 0px;
  color: #73000c;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #73000c;
}
.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  margin: 1rem 0;
  border-radius: 4px;
}
.p-message .p-message-wrapper {
  padding: 1rem 1.5rem;
}
.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-message.p-message-info {
  background: #B3E5FC;
  border: solid #0891cf;
  border-width: 0 0 0 6px;
  color: #044868;
}
.p-message.p-message-info .p-message-icon {
  color: #044868;
}
.p-message.p-message-info .p-message-close {
  color: #044868;
}
.p-message.p-message-success {
  background: #C8E6C9;
  border: solid #439446;
  border-width: 0 0 0 6px;
  color: #224a23;
}
.p-message.p-message-success .p-message-icon {
  color: #224a23;
}
.p-message.p-message-success .p-message-close {
  color: #224a23;
}
.p-message.p-message-warn {
  background: #FFECB3;
  border: solid #d9a300;
  border-width: 0 0 0 6px;
  color: #6d5100;
}
.p-message.p-message-warn .p-message-icon {
  color: #6d5100;
}
.p-message.p-message-warn .p-message-close {
  color: #6d5100;
}
.p-message.p-message-error {
  background: #FFCDD2;
  border: solid #e60017;
  border-width: 0 0 0 6px;
  color: #73000c;
}
.p-message.p-message-error .p-message-icon {
  color: #73000c;
}
.p-message.p-message-error .p-message-close {
  color: #73000c;
}
.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}
.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.p-message .p-message-summary {
  font-weight: 700;
}
.p-message .p-message-detail {
  margin-left: 0.5rem;
}

.p-toast {
  opacity: 0.9;
}
.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0 0 0 6px;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 2rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}
.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.p-toast .p-toast-message.p-toast-message-info {
  background: #B3E5FC;
  border: solid #0891cf;
  border-width: 0 0 0 6px;
  color: #044868;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #044868;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: #C8E6C9;
  border: solid #439446;
  border-width: 0 0 0 6px;
  color: #224a23;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #224a23;
}
.p-toast .p-toast-message.p-toast-message-warn {
  background: #FFECB3;
  border: solid #d9a300;
  border-width: 0 0 0 6px;
  color: #6d5100;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #6d5100;
}
.p-toast .p-toast-message.p-toast-message-error {
  background: #FFCDD2;
  border: solid #e60017;
  border-width: 0 0 0 6px;
  color: #73000c;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #73000c;
}

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: #f8f9fa;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #f8f9fa;
}
.p-galleria .p-galleria-item-nav {
  background: transparent;
  color: #f8f9fa;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 4px;
  margin: 0 0.5rem;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(255, 255, 255, 0.1);
  color: #f8f9fa;
}
.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: #f8f9fa;
  padding: 1rem;
}
.p-galleria .p-galleria-indicators {
  padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: #e9ecef;
  width: 1rem;
  height: 1rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #dee2e6;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #E3F2FD;
  color: #495057;
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: rgba(255, 255, 255, 0.4);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: rgba(255, 255, 255, 0.6);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #E3F2FD;
  color: #495057;
}
.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: transparent;
  color: #f8f9fa;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #f8f9fa;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
  transition: box-shadow 0.2s;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}

.p-galleria-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}

.p-avatar {
  background-color: #dee2e6;
  border-radius: 4px;
}
.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}
.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}
.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}
.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 2rem;
}
.p-avatar img {
  width: auto !important;
  max-width: 100%;
  height: auto !important;
  max-height: 100%;
}

.p-avatar-group .p-avatar {
  border: 2px solid #ffffff;
}

.p-badge {
  background: #493064;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: #607D8B;
  color: #ffffff;
}
.p-badge.p-badge-success {
  background-color: #689F38;
  color: #ffffff;
}
.p-badge.p-badge-info {
  background-color: #0288D1;
  color: #ffffff;
}
.p-badge.p-badge-warning {
  background-color: #FBC02D;
  color: #212529;
}
.p-badge.p-badge-danger {
  background-color: #D32F2F;
  color: #ffffff;
}
.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.p-blockui.p-component-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.p-chip {
  background-color: #dee2e6;
  color: #495057;
  border-radius: 16px;
  padding: 0 0.5rem;
}
.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  margin-left: 0.5rem;
}
.p-chip img {
  width: 2rem;
  height: 2rem;
  margin-left: -0.5rem;
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-chip .pi-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}

.p-inplace .p-inplace-display {
  padding: 0.5rem 0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: #e9ecef;
  color: #495057;
}
.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #b69dd0;
}

.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #dee2e6;
  border-radius: 4px;
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #493064;
}
.p-progressbar .p-progressbar-label {
  color: #495057;
  line-height: 1.5rem;
}

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-scrolltop.p-link {
  background: rgba(0, 0, 0, 0.7);
}
.p-scrolltop.p-link:hover {
  background: rgba(0, 0, 0, 0.8);
}
.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: #f8f9fa;
}

.p-skeleton {
  background-color: #e9ecef;
  border-radius: 4px;
}
.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.p-tag {
  background: #493064;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
}
.p-tag.p-tag-success {
  background-color: #689F38;
  color: #ffffff;
}
.p-tag.p-tag-info {
  background-color: #0288D1;
  color: #ffffff;
}
.p-tag.p-tag-warning {
  background-color: #FBC02D;
  color: #212529;
}
.p-tag.p-tag-danger {
  background-color: #D32F2F;
  color: #ffffff;
}
.p-tag .p-tag-icon {
  margin-right: 0.25rem;
  font-size: 0.75rem;
}

.p-terminal {
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  padding: 1rem;
}
.p-terminal .p-terminal-input {
  font-size: 1rem;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* Add your customizations of the theme styles here */
/* Add your customizations of the layout variables here */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: local("Nunito Regular"), local("Nunito-Regular"), url("/assets/fonts/nunito-v12-latin-ext_latin-regular.woff2") format("woff2"), url("/assets/fonts/nunito-v12-latin-ext_latin-regular.woff") format("woff");
  /* Modern Browsers */
}
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  src: local("Nunito SemiBold"), local("Nunito-SemiBold"), url("/assets/fonts/nunito-v12-latin-ext_latin-600.woff2") format("woff2"), url("/assets/fonts/nunito-v12-latin-ext_latin-600.woff") format("woff");
  /* Modern Browsers */
}
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  src: local("Nunito Bold"), local("Nunito-Bold"), url("/assets/fonts/nunito-v12-latin-ext_latin-700.woff2") format("woff2"), url("/assets/fonts/nunito-v12-latin-ext_latin-700.woff") format("woff");
  /* Modern Browsers */
}
/* Utils */
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

.card {
  background: #ffffff;
  padding: 1.25rem;
  border: 0 none;
  margin-bottom: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.card:last-child {
  margin-bottom: 0;
}
.card .card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.card .card-subtitle {
  color: #6c757d;
  font-weight: 600;
  margin: -1rem 0 1rem 0;
}

.p-toast.p-toast-top-right, .p-toast.p-toast-top-left, .p-toast.p-toast-top-center {
  top: 70px;
}

h1, h2, h3, h4, h5, h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}
h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top: 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

mark {
  background: #FFF8E1;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
  font-family: monospace;
}

blockquote {
  margin: 1rem 0;
  padding: 0 2rem;
  border-left: 4px solid #90A4AE;
}

hr {
  border-top: solid #dee2e6;
  border-width: 1px 0 0 0;
  margin: 1rem 0;
}

p {
  margin: 0 0 1rem 0;
  line-height: 1.5;
}
p:last-child {
  margin-bottom: 0;
}

@-webkit-keyframes fade-in-up {
  from {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fade-out-down {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(0, 20px);
  }
}
@keyframes fade-out-down {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}
.fade-in-up {
  -webkit-animation-name: fade-in-up;
  animation-name: fade-in-up;
}

.fade-out-down {
  -webkit-animation-name: fade-out-down;
  animation-name: fade-out-down;
}

@-webkit-keyframes fade-in-right {
  from {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fade-out-right {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(0, 20px);
  }
}
@keyframes fade-out-right {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
}
.fade-in-right {
  -webkit-animation-name: fade-in-right;
  animation-name: fade-in-right;
}

.fade-out-right {
  -webkit-animation-name: fade-out-right;
  animation-name: fade-out-right;
}

@-webkit-keyframes modal-in {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
@keyframes modal-in {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
.modal-in {
  -webkit-animation-name: modal-in;
  animation-name: modal-in;
}

.loader-container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 100vh;
  background: #343A40;
}

.loader {
  position: relative;
  margin: auto;
}

.middle {
  top: 0;
  left: 0;
  width: 40px;
  height: 2px;
  background: white;
  position: absolute;
  animation: middleAnimation 2s infinite linear;
  animation-delay: -100ms;
}

.bottom {
  top: 0;
  left: 0;
  position: absolute;
  height: 30px;
  width: 2px;
  background: white;
  border-radius: 2px;
  transform-origin: bottom;
  transform: rotate(45deg);
  animation: bottomAnimation 4s infinite;
}

.bottom:nth-child(1) {
  animation-delay: -1000ms;
}

.bottom:nth-child(2) {
  animation-delay: -2000ms;
}

.bottom:nth-child(3) {
  animation-delay: -3000ms;
}

.bottom-section {
  position: absolute;
  transform: translateY(-16px);
}

.top-section {
  position: absolute;
  transform: translateY(16px);
  transform: scaleY(-1);
}

@keyframes bottomAnimation {
  0% {
    transform: rotate(45deg) scaleY(1);
  }
  25% {
    transform: rotate(0) scaleY(0.7);
  }
  50% {
    transform: rotate(-45deg) scaleY(1);
  }
  75% {
    transform: rotate(0) scaleY(0.7);
  }
  100% {
    transform: rotate(45deg) scaleY(1);
  }
}
@keyframes middleAnimation {
  0% {
    transform: translate(-19px, -9px) scaleX(1);
  }
  25% {
    transform: translate(-19px, -9px) scaleX(0.6);
  }
  50% {
    transform: translate(-19px, -9px) scaleX(1);
  }
  75% {
    transform: translate(-19px, -9px) scaleX(0.7);
  }
  100% {
    transform: translate(-19px, -9px) scaleX(1);
  }
}
* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 1rem;
}

body {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  color: #495057;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background: #f8f9fa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: #493064;
}

.layout-mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.05, 0.74, 0.2, 0.99);
  animation-fill-mode: forwards;
}

.layout-ajax-loader-icon {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  font-size: 2rem;
  color: #6c757d;
}

.layout-topbar {
  background: #ffffff;
  height: 4rem;
  padding: 0 2rem;
  border-bottom: 1px solid #dee2e6;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #495057;
}
.layout-topbar .topbar-left {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.layout-topbar .menu-button {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  color: #495057;
  overflow: hidden;
  border-radius: 4px;
  background: #f8f9fa;
  transition: background-color 0.2s, box-shadow 0.2s;
  display: none;
  cursor: pointer;
  outline: 0 none;
}
.layout-topbar .menu-button:hover {
  background: #e9ecef;
}
.layout-topbar .menu-button:focus {
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.layout-topbar .menu-button i {
  line-height: inherit;
  font-size: 1.5rem;
}
.layout-topbar .topbar-separator {
  display: block;
  width: 1px;
  border-left: 1px solid #dee2e6;
  align-self: stretch;
  margin: 0 1rem;
}
.layout-topbar .mobile-logo {
  display: none;
}
.layout-topbar .viewname {
  font-weight: 600;
  font-size: 1.25rem;
}
.layout-topbar .topbar-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.layout-topbar .topbar-menu > li {
  margin-left: 1rem;
  position: relative;
}
.layout-topbar .topbar-menu > li:first {
  margin-left: 0;
}
.layout-topbar .topbar-menu > li.profile-item {
  padding: 0 1rem 0 1rem;
}
.layout-topbar .topbar-menu > li.profile-item > a {
  width: auto;
  height: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0 0.5rem 0 0;
}
.layout-topbar .topbar-menu > li.profile-item .profile-image {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.5rem;
}
.layout-topbar .topbar-menu > li.profile-item p-avatar {
  max-height: 2.5rem;
}
.layout-topbar .topbar-menu > li.right-sidebar-item > a {
  background: #f8f9fa;
}
.layout-topbar .topbar-menu > li > a {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  color: #495057;
  overflow: hidden;
  border-radius: 4px;
  transition: background-color 0.2s, box-shadow 0.2s;
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  outline: 0 none;
}
.layout-topbar .topbar-menu > li > a:hover {
  background: #e9ecef;
}
.layout-topbar .topbar-menu > li > a:focus {
  box-shadow: 0 0 0 0.2rem #b69dd0;
}
.layout-topbar .topbar-menu > li > a i {
  line-height: inherit;
  font-size: 1.5rem;
}
.layout-topbar .topbar-menu > li > a .topbar-badge {
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.714rem;
  border-radius: 50%;
  font-weight: 600;
  background: #1976D2;
  color: #ffffff;
}
.layout-topbar .topbar-menu > li > ul {
  display: none;
  position: absolute;
  background: #ffffff;
  list-style: none;
  margin: 0;
  padding: 1rem;
  top: 3.25rem;
  right: 0;
  z-index: 999;
  min-width: 250px;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  animation-duration: 0.12s;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  border-radius: 4px;
  transform-origin: center top;
}
.layout-topbar .topbar-menu > li > ul li a {
  color: #495057;
  padding: 0.5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  transition: background-color 0.2s;
  cursor: pointer;
}
.layout-topbar .topbar-menu > li > ul li a:hover {
  background: #e9ecef;
}
.layout-topbar .topbar-menu > li > ul.notifications-menu .notification-item {
  margin-left: 0.5rem;
}
.layout-topbar .topbar-menu > li > ul.notifications-menu .notification-summary {
  font-weight: 600;
}
.layout-topbar .topbar-menu > li > ul.notifications-menu .notification-detail {
  color: #6c757d;
}
.layout-topbar .topbar-menu > li > ul.profile-menu span {
  margin-left: 0.5rem;
}
.layout-topbar .topbar-menu > li.active-menuitem > ul {
  display: block;
}

.layout-sidebar {
  width: 16rem;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  user-select: none;
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}
.layout-sidebar .logo {
  text-align: center;
  display: block;
  outline: 0 none;
  padding: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  transition: background-color 0.2s, box-shadow 0.2s;
}
.layout-sidebar .logo-image {
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
  margin-right: 0.5rem;
}
.layout-sidebar .app-name {
  vertical-align: middle;
  font-size: 1.5rem;
  letter-spacing: 0.2px;
}

.layout-menu-container {
  overflow: auto;
  flex: 1;
  padding: 1rem;
}

.layout-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.layout-menu .menu-separator {
  margin: 1rem 0;
}
.layout-menu .layout-root-menuitem > .layout-menuitem-root-text {
  font-size: 0.857rem;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0.5rem 0 1rem 0;
}
.layout-menu .layout-root-menuitem > a {
  display: none;
}
.layout-menu .layout-root-menuitem .layout-menu-tooltip {
  display: none;
}
.layout-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.layout-menu ul a {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  outline: 0 none;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
}
.layout-menu ul a .layout-menuitem-icon {
  margin-right: 0.5rem;
}
.layout-menu ul a .layout-submenu-toggler {
  font-size: 75%;
  margin-left: auto;
  transition: transform 0.2s;
}
.layout-menu ul a.rotated-icon .layout-menuitem-icon {
  transform: rotate(90deg);
}
.layout-menu ul a.active-route {
  font-weight: 700;
}
.layout-menu ul li.active-menuitem {
  border-radius: 4px;
}
.layout-menu ul li.active-menuitem > a .layout-submenu-toggler {
  transform: rotate(-180deg);
}
.layout-menu ul ul {
  overflow: hidden;
  border-radius: 4px;
}
.layout-menu ul ul li a {
  padding-left: 1.5rem;
}
.layout-menu ul ul li li a {
  padding-left: 2.5rem;
}
.layout-menu ul ul li li li a {
  padding-left: 3.5rem;
}
.layout-menu ul ul li li li li a {
  padding-left: 4.5rem;
}
.layout-menu ul ul li li li li li a {
  padding-left: 5.5rem;
}
.layout-menu ul ul li li li li li li a {
  padding-left: 6.5rem;
}

@media screen and (min-width: 992px) {
  .layout-wrapper.layout-slim .topbar-left .menu-button {
    display: none;
  }
  .layout-wrapper.layout-slim .topbar-left .topbar-separator {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-sidebar {
    width: 6rem;
    overflow: visible;
    z-index: 999;
  }
  .layout-wrapper.layout-slim .layout-sidebar .logo-image {
    margin-right: 0;
  }
  .layout-wrapper.layout-slim .layout-sidebar .app-name {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-sidebar .layout-menu-container {
    overflow: visible;
  }
  .layout-wrapper.layout-slim .layout-content-wrapper {
    margin-left: 6rem;
  }
  .layout-wrapper.layout-slim .layout-menu ul {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu li.active-menuitem > ul {
    display: block;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem {
    position: relative;
    border-radius: 4px;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > .layout-menuitem-root-text {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > a {
    display: block;
    text-align: center;
    border-radius: 4px;
    padding: 0.5rem 0;
    cursor: pointer;
    outline: none;
    transition: background-color 0.2s;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > a .layout-submenu-toggler {
    display: none;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > a .layout-menuitem-icon {
    font-size: 1.25rem;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > a .layout-menuitem-text {
    font-size: 0.875rem;
    display: block;
    margin-top: 0.25rem;
  }
  .layout-wrapper.layout-slim .layout-menu .layout-root-menuitem > ul {
    position: absolute;
    left: 5rem;
    top: 0;
    min-width: 15rem;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 1rem;
    animation-duration: 0.4s;
    animation-timing-function: cubic-bezier(0.05, 0.74, 0.2, 0.99);
  }
}
.layout-sidebar-blue .layout-sidebar {
  background: #1976D2;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #1976D2 10%, #1565C0 100%);
}
.layout-sidebar-blue .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-blue .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-blue .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-blue .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-blue .layout-sidebar .layout-menu li.active-menuitem > a {
  color: #ffffff;
}
.layout-sidebar-blue .layout-sidebar .layout-menu li a {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-blue .layout-sidebar .layout-menu li a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-blue .layout-sidebar .layout-menu li a:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-blue .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-blue .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-blue .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-blue.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #1976D2;
    background-image: linear-gradient(180deg, #1976D2 10%, #1565C0 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-blue .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-bluegray .layout-sidebar {
  background: #455A64;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #455A64 10%, #37474F 100%);
}
.layout-sidebar-bluegray .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-bluegray .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-bluegray .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-bluegray .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-bluegray .layout-sidebar .layout-menu li.active-menuitem > a {
  color: #ffffff;
}
.layout-sidebar-bluegray .layout-sidebar .layout-menu li a {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-bluegray .layout-sidebar .layout-menu li a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-bluegray .layout-sidebar .layout-menu li a:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-bluegray .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-bluegray .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-bluegray .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-bluegray.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #455A64;
    background-image: linear-gradient(180deg, #455A64 10%, #37474F 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-bluegray .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-brown .layout-sidebar {
  background: #5D4037;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #5D4037 10%, #4E342E 100%);
}
.layout-sidebar-brown .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-brown .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-brown .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-brown .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-brown .layout-sidebar .layout-menu li.active-menuitem > a {
  color: #ffffff;
}
.layout-sidebar-brown .layout-sidebar .layout-menu li a {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-brown .layout-sidebar .layout-menu li a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-brown .layout-sidebar .layout-menu li a:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-brown .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-brown .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-brown .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-brown.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #5D4037;
    background-image: linear-gradient(180deg, #5D4037 10%, #4E342E 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-brown .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-cyan .layout-sidebar {
  background: #0097A7;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #0097A7 10%, #00838F 100%);
}
.layout-sidebar-cyan .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-cyan .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-cyan .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-cyan .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-cyan .layout-sidebar .layout-menu li.active-menuitem > a {
  color: #ffffff;
}
.layout-sidebar-cyan .layout-sidebar .layout-menu li a {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-cyan .layout-sidebar .layout-menu li a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-cyan .layout-sidebar .layout-menu li a:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-cyan .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-cyan .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-cyan .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-cyan.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #0097A7;
    background-image: linear-gradient(180deg, #0097A7 10%, #00838F 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-cyan .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-green .layout-sidebar {
  background: #388E3C;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #388E3C 10%, #388E3C 100%);
}
.layout-sidebar-green .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-green .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-green .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-green .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-green .layout-sidebar .layout-menu li.active-menuitem > a {
  color: #ffffff;
}
.layout-sidebar-green .layout-sidebar .layout-menu li a {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-green .layout-sidebar .layout-menu li a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-green .layout-sidebar .layout-menu li a:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-green .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-green .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-green .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-green.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #388E3C;
    background-image: linear-gradient(180deg, #388E3C 10%, #388E3C 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-green .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-indigo .layout-sidebar {
  background: #303F9F;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #303F9F 10%, #283593 100%);
}
.layout-sidebar-indigo .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-indigo .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-indigo .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-indigo .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-indigo .layout-sidebar .layout-menu li.active-menuitem > a {
  color: #ffffff;
}
.layout-sidebar-indigo .layout-sidebar .layout-menu li a {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-indigo .layout-sidebar .layout-menu li a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-indigo .layout-sidebar .layout-menu li a:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-indigo .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-indigo .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-indigo .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-indigo.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #303F9F;
    background-image: linear-gradient(180deg, #303F9F 10%, #283593 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-indigo .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-deeppurple .layout-sidebar {
  background: #512DA8;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #512DA8 10%, #4527A0 100%);
}
.layout-sidebar-deeppurple .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-deeppurple .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-deeppurple .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-deeppurple .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-deeppurple .layout-sidebar .layout-menu li.active-menuitem > a {
  color: #ffffff;
}
.layout-sidebar-deeppurple .layout-sidebar .layout-menu li a {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-deeppurple .layout-sidebar .layout-menu li a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-deeppurple .layout-sidebar .layout-menu li a:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-deeppurple .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-deeppurple .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-deeppurple .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-deeppurple.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #512DA8;
    background-image: linear-gradient(180deg, #512DA8 10%, #4527A0 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-deeppurple .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-orange .layout-sidebar {
  background: #F57C00;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #F57C00 10%, #EF6C00 100%);
}
.layout-sidebar-orange .layout-sidebar .logo {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.layout-sidebar-orange .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-orange .layout-sidebar .app-name {
  color: rgba(0, 0, 0, 0.8);
}
.layout-sidebar-orange .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.layout-sidebar-orange .layout-sidebar .layout-menu li.active-menuitem > a {
  color: rgba(0, 0, 0, 0.9);
}
.layout-sidebar-orange .layout-sidebar .layout-menu li a {
  color: rgba(0, 0, 0, 0.8);
}
.layout-sidebar-orange .layout-sidebar .layout-menu li a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-orange .layout-sidebar .layout-menu li a:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-orange .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(0, 0, 0, 0.6);
}
.layout-sidebar-orange .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-orange .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-orange.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #F57C00;
    background-image: linear-gradient(180deg, #F57C00 10%, #EF6C00 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-orange .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-pink .layout-sidebar {
  background: #C2185B;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #C2185B 10%, #AD1457 100%);
}
.layout-sidebar-pink .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-pink .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-pink .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-pink .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-pink .layout-sidebar .layout-menu li.active-menuitem > a {
  color: #ffffff;
}
.layout-sidebar-pink .layout-sidebar .layout-menu li a {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-pink .layout-sidebar .layout-menu li a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-pink .layout-sidebar .layout-menu li a:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-pink .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-pink .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-pink .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-pink.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #C2185B;
    background-image: linear-gradient(180deg, #C2185B 10%, #AD1457 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-pink .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-purple .layout-sidebar {
  background: #7B1FA2;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #7B1FA2 10%, #6A1B9A 100%);
}
.layout-sidebar-purple .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-purple .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-purple .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-purple .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-purple .layout-sidebar .layout-menu li.active-menuitem > a {
  color: #ffffff;
}
.layout-sidebar-purple .layout-sidebar .layout-menu li a {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-purple .layout-sidebar .layout-menu li a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-purple .layout-sidebar .layout-menu li a:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-purple .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-purple .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-purple .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-purple.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #7B1FA2;
    background-image: linear-gradient(180deg, #7B1FA2 10%, #6A1B9A 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-purple .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-teal .layout-sidebar {
  background: #00796B;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #00796B 10%, #00695C 100%);
}
.layout-sidebar-teal .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-teal .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-teal .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-teal .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-teal .layout-sidebar .layout-menu li.active-menuitem > a {
  color: #ffffff;
}
.layout-sidebar-teal .layout-sidebar .layout-menu li a {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-teal .layout-sidebar .layout-menu li a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-teal .layout-sidebar .layout-menu li a:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-teal .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-teal .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
.layout-sidebar-teal .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 992px) {
  .layout-sidebar-teal.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #00796B;
    background-image: linear-gradient(180deg, #00796B 10%, #00695C 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-teal .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-white .layout-sidebar {
  background: #ffffff;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #ffffff 10%, #ffffff 100%);
}
.layout-sidebar-white .layout-sidebar .logo {
  border-bottom: 1px solid #dee2e6;
}
.layout-sidebar-white .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-white .layout-sidebar .app-name {
  color: #495057;
}
.layout-sidebar-white .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid #dee2e6;
}
.layout-sidebar-white .layout-sidebar .layout-menu li.active-menuitem > a {
  color: #495057;
}
.layout-sidebar-white .layout-sidebar .layout-menu li a {
  color: #495057;
}
.layout-sidebar-white .layout-sidebar .layout-menu li a:hover {
  background: #e9ecef;
}
.layout-sidebar-white .layout-sidebar .layout-menu li a:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-white .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: #6c757d;
}
.layout-sidebar-white .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: #ffffff;
}
.layout-sidebar-white .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: #ffffff;
}
@media screen and (min-width: 992px) {
  .layout-sidebar-white.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #ffffff;
    background-image: linear-gradient(180deg, #ffffff 10%, #ffffff 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-white .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-darkgray .layout-sidebar {
  background: #343a40;
  border-right: 0 none;
  background-image: linear-gradient(180deg, #343a40 10%, #2e3439 100%);
}
.layout-sidebar-darkgray .layout-sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-darkgray .layout-sidebar .logo:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-darkgray .layout-sidebar .app-name {
  color: #ffffff;
}
.layout-sidebar-darkgray .layout-sidebar .layout-menu .menu-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.layout-sidebar-darkgray .layout-sidebar .layout-menu li.active-menuitem > a {
  color: #ffffff;
}
.layout-sidebar-darkgray .layout-sidebar .layout-menu li a {
  color: rgba(255, 255, 255, 0.8);
}
.layout-sidebar-darkgray .layout-sidebar .layout-menu li a:hover {
  background: #4b545c;
}
.layout-sidebar-darkgray .layout-sidebar .layout-menu li a:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}
.layout-sidebar-darkgray .layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  color: rgba(255, 255, 255, 0.4);
}
.layout-sidebar-darkgray .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
  background: #3f474e;
}
.layout-sidebar-darkgray .layout-sidebar .layout-menu .layout-root-menuitem > ul > li.active-menuitem {
  background: #3f474e;
}
@media screen and (min-width: 992px) {
  .layout-sidebar-darkgray.layout-slim .layout-sidebar .layout-menu .layout-root-menuitem > ul {
    background: #343a40;
    background-image: linear-gradient(180deg, #343a40 10%, #2e3439 100%);
  }
}
@media screen and (max-width: 992px) {
  .layout-sidebar-darkgray .layout-sidebar .layout-menu .layout-root-menuitem.active-menuitem {
    background: transparent;
  }
}

.layout-sidebar-right {
  width: 16rem;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  padding: 1rem;
  overflow: auto;
  background: #ffffff;
  z-index: 999;
  border-left: 0 none;
  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}
.layout-sidebar-right.layout-sidebar-right-active {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  transform: translateX(0);
}

/* Right Sidebar Demo Content */
.layout-sidebar-right p {
  font-size: 0.875rem;
  color: #6c757d;
}
.layout-sidebar-right .withdraw {
  margin-top: -0.5rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.layout-sidebar-right .withdraw > div {
  padding: 0.5rem;
}
.layout-sidebar-right .withdraw input, .layout-sidebar-right .withdraw button {
  font-size: 0.875rem;
}

.layout-content-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 100vh;
  transition: margin-left 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}

.layout-content {
  padding: 2rem;
  flex: 1 1 auto;
  -ms-flex: 1 1 auto;
}

.layout-footer {
  background: #ffffff;
  height: 4rem;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding: 0 2rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.layout-footer .footer-logo-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.layout-footer .footer-logo-container img {
  width: 1.5rem;
  height: 1.5rem;
}
.layout-footer .footer-logo-container .app-name {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin-left: 0.5rem;
}
.layout-footer .copyright {
  font-size: 0.875rem;
  color: #6c757d;
}

@media screen and (min-width: 992px) {
  .layout-wrapper.layout-static .layout-content-wrapper {
    margin-left: 16rem;
  }
  .layout-wrapper.layout-static-inactive .layout-topbar .menu-button i:before {
    content: "";
  }
  .layout-wrapper.layout-static-inactive .layout-sidebar {
    transform: translateX(-100%);
  }
  .layout-wrapper.layout-static-inactive .layout-content-wrapper {
    margin-left: 0;
  }
  .layout-wrapper.layout-overlay .layout-topbar .menu-button i:before {
    content: "";
  }
  .layout-wrapper.layout-overlay .layout-content-wrapper {
    margin-left: 0;
  }
  .layout-wrapper.layout-overlay .layout-sidebar {
    z-index: 999;
    transform: translateX(-100%);
    box-shadow: none;
  }
  .layout-wrapper.layout-overlay.layout-overlay-active .layout-sidebar {
    transform: translateX(0);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
}
@media screen and (max-width: 991px) {
  .blocked-scroll {
    overflow: hidden;
  }

  .layout-wrapper .layout-topbar {
    height: 7.125rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 998;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .layout-wrapper .layout-topbar .topbar-left {
    width: 100%;
    padding: 0.5rem 0;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6;
  }
  .layout-wrapper .layout-topbar .topbar-left .topbar-separator {
    display: none;
  }
  .layout-wrapper .layout-topbar .topbar-right {
    width: 100%;
    padding: 0.5rem 0;
  }
  .layout-wrapper .layout-topbar .menu-button {
    display: block;
  }
  .layout-wrapper .layout-topbar .mobile-logo {
    display: block;
    height: 2rem;
  }
  .layout-wrapper .layout-topbar .viewname {
    font-size: 1rem;
  }
  .layout-wrapper .layout-topbar .topbar-menu {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li {
    margin-left: 0;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li.profile-item {
    border-left: 0;
    border-right: 0;
    padding: 0;
    margin-left: 15px;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li.profile-item p-avatar {
    height: 2rem;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li.profile-item > a {
    padding: 0;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li.profile-item .profile-image {
    width: 2rem;
    height: 2rem;
    margin: 0;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li.profile-item .profile-name {
    display: none;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li > ul {
    top: 2.75rem;
  }
  .layout-wrapper .layout-content-wrapper {
    margin-left: 0;
    padding-top: 7.125rem;
  }
  .layout-wrapper .layout-sidebar {
    z-index: 999;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
    transform: translateX(-100%);
  }
  .layout-wrapper.layout-mobile-active .layout-sidebar {
    transform: translateX(0);
  }
  .layout-wrapper.layout-mobile-active .layout-mask {
    display: block;
  }
  .layout-wrapper .layout-search .search-container {
    width: 75vw;
  }
}
@media screen and (max-width: 576px) {
  .layout-wrapper .layout-topbar .topbar-menu > li {
    position: static;
  }
  .layout-wrapper .layout-topbar .topbar-menu > li > ul {
    position: fixed;
    top: 7.125rem;
    left: 0;
    width: 100%;
    right: auto;
  }
}
.layout-search {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  pointer-events: none;
}
.layout-search .search-container {
  background: #adb5bd;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.5rem;
  padding: 2rem;
  width: 50vw;
  border-radius: 0.625rem;
  position: relative;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: 0 none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  pointer-events: all;
}
.layout-search .search-container input {
  appearance: none;
  font-size: 1.5rem;
  text-indent: 2.5rem;
  padding: 0.5rem;
  width: 100%;
}
.layout-search .search-container i {
  color: #6c757d;
  width: 2rem;
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  margin-top: -0.75rem;
  margin-left: 1rem;
}

.login-body {
  background: #f8f9fa;
  color: #495057;
}
.login-body .login-wrapper {
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
}
.login-body .login-wrapper .login-panel {
  width: 30%;
  text-align: center;
  padding: 40px 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-direction: column;
  flex-direction: column;
}
.login-body .login-wrapper .login-panel label {
  text-align: left;
}
.login-body .login-wrapper .login-panel .login-form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
}
.login-body .login-wrapper .login-panel .login-form > p {
  font-weight: 600;
  margin: 0;
  color: #6c757d;
  margin-bottom: 32px;
}
.login-body .login-wrapper .login-panel .login-form > p > a {
  color: #493064;
  cursor: pointer;
}
.login-body .login-wrapper .login-panel .login-form > input {
  width: 85%;
  max-width: 310px;
  margin-bottom: 20px;
}
.login-body .login-wrapper .login-panel .login-form > button {
  width: 85%;
  max-width: 310px;
}
.login-body .login-wrapper .login-panel p {
  font-weight: 600;
  margin: 0;
  color: #6c757d;
}
.login-body .login-wrapper .login-panel p > a {
  color: #493064;
}
.login-body .login-wrapper .login-image {
  width: 70%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 40px 20px;
}
.login-body .login-wrapper .login-image .login-image-content > h1 {
  font-size: 60px;
  margin: 0;
  color: #ffffff;
}
.login-body .login-wrapper .login-image .login-image-content h3 {
  font-size: 24px;
  color: #ffffff;
  margin: 0;
  font-weight: 600;
  margin-top: 32px;
}
.login-body .login-wrapper .login-image .image-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.login-body .login-wrapper .login-image .image-footer > p {
  font-weight: 600;
  margin: 0;
  color: #ffffff;
  margin-right: 20px;
}
.login-body .login-wrapper .login-image .image-footer .icons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.login-body .login-wrapper .login-image .image-footer .icons i {
  font-size: 22px;
  color: #2196F3;
  margin-right: 20px;
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: opacity 0.2s;
  background: #ffffff;
  opacity: 0.7;
}
.login-body .login-wrapper .login-image .image-footer .icons i:hover {
  opacity: 1;
}

@media (min-width: 993px) {
  .login-body .login-wrapper .login-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("/assets/images/pages/bg-login.jpg");
  }
}
@media (max-width: 992px) {
  .login-body .login-wrapper .login-panel {
    width: 100%;
    z-index: 2;
  }
  .login-body .login-wrapper .login-image {
    display: none;
  }
}
.invoice {
  padding: 2rem;
}
.invoice .invoice-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.invoice .invoice-company .logo-image {
  width: 75px;
  margin-bottom: 0.5rem;
}
.invoice .invoice-company div {
  margin-bottom: 0.5rem;
}
.invoice .invoice-company .company-name {
  font-weight: 700;
  font-size: 1.5rem;
}
.invoice .invoice-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: right;
}
.invoice .invoice-details {
  width: 15rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.invoice .invoice-details > div {
  width: 50%;
  margin-bottom: 0.5rem;
}
.invoice .invoice-details .invoice-label {
  text-align: left;
  font-weight: 700;
}
.invoice .invoice-details .invoice-value {
  text-align: right;
}
.invoice .invoice-to {
  margin-top: 1.5rem;
  padding-top: 2rem;
  border-top: 1px solid #dee2e6;
}
.invoice .invoice-to .bill-to {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.invoice .invoice-to .invoice-to-info div {
  margin-bottom: 0.5rem;
}
.invoice .invoice-items {
  margin-top: 2rem;
  padding-top: 2rem;
}
.invoice .invoice-items table {
  width: 100%;
  border-collapse: collapse;
}
.invoice .invoice-items table tr {
  border-bottom: 1px solid #dee2e6;
}
.invoice .invoice-items table th {
  font-weight: 700;
}
.invoice .invoice-items table th, .invoice .invoice-items table td {
  padding: 1rem;
  text-align: right;
}
.invoice .invoice-items table th:first-child, .invoice .invoice-items table td:first-child {
  text-align: left;
}
.invoice .invoice-summary {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 2.5rem;
  padding-top: 2.5rem;
}
.invoice .invoice-summary .invoice-value {
  font-weight: 700;
}

@media print {
  body * {
    visibility: hidden;
  }

  #invoice-content * {
    visibility: visible;
  }

  #invoice-content {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
  }
}
.help-page p {
  margin: 0;
}
.help-page .help-search {
  background: url("/assets/images/pages/bg-help.png") repeat;
  padding: 0;
  text-align: center;
}
.help-page .help-search .help-search-content {
  padding: 5rem 12rem;
}
.help-page .help-search .search-container {
  font-size: 1.5rem;
  padding: 1rem;
  position: relative;
}
.help-page .help-search .search-container input {
  appearance: none;
  font-size: 1.5rem;
  text-indent: 2.5rem;
  padding: 0.5rem;
  width: 100%;
}
.help-page .help-search .search-container i {
  color: #495057;
  width: 2rem;
  position: absolute;
  margin-left: 1rem;
  top: 50%;
  margin-top: -0.5rem;
}
.help-page .status-bars {
  margin-top: 1rem;
  display: -ms-flexbox;
  display: flex;
}
.help-page .status-bars .status-bar {
  flex: 1 1 0;
  -ms-flex: 1 1 0px;
  background: #8BC34A;
  height: 50px;
  margin-right: 0.25rem;
  transition: transform 0.2s;
}
.help-page .status-bars .status-bar:last-child {
  margin-right: 0;
}
.help-page .status-bars .status-bar.status-bar-failure {
  background: #EC407A;
}
.help-page .status-bars .status-bar:hover {
  transform: scale(1.1);
}
.help-page .status-bar-footer {
  padding: 1rem 0 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.help-page .blog-post {
  height: 150px;
  border-radius: 4px;
  margin: 3rem 2rem;
  position: relative;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.help-page .blog-post:last-child {
  margin-bottom: 1rem;
}
.help-page .blog-post img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.help-page .blog-post .blog-text {
  position: absolute;
  left: 20px;
  top: 30px;
}
.help-page .blog-post .blog-text h1 {
  font-size: 1.25rem;
  color: #ffffff;
  margin-bottom: 1rem;
  font-weight: 700;
}
.help-page .blog-post .blog-text span {
  color: #ffffff;
  font-weight: 600;
}
.help-page .blog-post .blog-profile {
  position: absolute;
  top: -25px;
  left: -25px;
}
.help-page .blog-post .blog-profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.help-page .blog-post:nth-child(1) {
  background-image: url("/assets/images/pages/help/blog1.jpg");
}
.help-page .blog-post:nth-child(2) {
  background-image: url("/assets/images/pages/help/blog2.jpg");
}
.help-page .blog-post:nth-child(3) {
  background-image: url("/assets/images/pages/help/blog3.jpg");
}

@media screen and (max-width: 991px) {
  .help-page .help-search .help-search-content {
    padding: 6rem 2rem;
  }
}
.widget-overview-box {
  min-height: 6rem;
  position: relative;
  border-left: 4px solid;
}
.widget-overview-box .overview-title {
  font-weight: 600;
  font-size: 1.25rem;
  vertical-align: middle;
  margin-left: 0.5rem;
}
.widget-overview-box .overview-icon {
  display: inline-block;
  border-radius: 4px;
  width: 2em;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  vertical-align: middle;
}
.widget-overview-box .overview-icon i {
  font-size: 1rem;
  line-height: inherit;
}
.widget-overview-box .overview-detail {
  text-align: center;
  margin-top: 1rem;
}
.widget-overview-box .overview-detail .p-col-6 {
  padding: 0.5rem;
}
.widget-overview-box .overview-number {
  font-size: 1.5rem;
  font-weight: 600;
}
.widget-overview-box .overview-subtext {
  color: #6c757d;
  font-weight: 600;
  margin-top: 0.25rem;
}
.widget-overview-box .p-col-6:first-child {
  border-right: 1px solid #dee2e6;
}
.widget-overview-box.widget-overview-box-1 {
  border-left-color: #64B5F6;
}
.widget-overview-box.widget-overview-box-1 .overview-title {
  color: #64B5F6;
}
.widget-overview-box.widget-overview-box-1 .overview-icon {
  background: #64B5F6;
  color: #1976D2;
}
.widget-overview-box.widget-overview-box-2 {
  border-left-color: #7986CB;
}
.widget-overview-box.widget-overview-box-2 .overview-title {
  color: #7986CB;
}
.widget-overview-box.widget-overview-box-2 .overview-icon {
  background: #7986CB;
  color: #303F9F;
}
.widget-overview-box.widget-overview-box-3 {
  border-left-color: #4DB6AC;
}
.widget-overview-box.widget-overview-box-3 .overview-title {
  color: #4DB6AC;
}
.widget-overview-box.widget-overview-box-3 .overview-icon {
  background: #4DB6AC;
  color: #00796B;
}
.widget-overview-box.widget-overview-box-4 {
  border-left-color: #4DD0E1;
}
.widget-overview-box.widget-overview-box-4 .overview-title {
  color: #4DD0E1;
}
.widget-overview-box.widget-overview-box-4 .overview-icon {
  background: #4DD0E1;
  color: #0097A7;
}

.widget-tasks .task {
  padding: 1rem 0;
}
.widget-tasks .task-name {
  margin-bottom: 0.5rem;
}
.widget-tasks .task-name span {
  font-weight: 600;
}
.widget-tasks .task-progress {
  border-radius: 4px;
  height: 0.5rem;
  background: #dee2e6;
}
.widget-tasks .task-progress > div {
  border-radius: 4px;
  height: 100%;
}
.widget-tasks .task-1 .task-progress > div {
  background: #64B5F6;
  width: 75%;
}
.widget-tasks .task-2 .task-progress > div {
  background: #A5D6A7;
  width: 60%;
}
.widget-tasks .task-3 .task-progress > div {
  background: #7986CB;
  width: 80%;
}
.widget-tasks .task-4 .task-progress > div {
  background: #9575CD;
  width: 40%;
}
.widget-tasks .task-5 .task-progress > div {
  background: #4DB6AC;
  width: 50%;
}

.widget-image-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.widget-image-list li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #dee2e6;
  padding: 1rem;
}
.widget-image-list li:first-child {
  border-top: 0 none;
  font-weight: 600;
  font-size: 1rem;
}
.widget-image-list li img {
  width: 64px;
  vertical-align: middle;
  margin-right: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.widget-image-list li .listitem-value {
  font-weight: 600;
}

.widget-feature-box {
  height: 100%;
  text-align: center;
  padding: 2rem;
}
.widget-feature-box > img {
  margin-bottom: 32px;
}
.widget-feature-box > h2 {
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 8px;
  color: #495057;
}
.widget-feature-box span {
  padding: 0 20px;
  font-size: 14px;
  display: block;
  color: #6c757d;
}

.widget-pricing-card {
  border-radius: 4px;
  background: #1F8CE3;
  color: #ffffff;
  text-align: center;
  padding: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}
.widget-pricing-card.deal {
  background-image: linear-gradient(45deg, #105da1 0%, #1f8ce3 100%);
}
.widget-pricing-card > span {
  display: block;
  font-size: 1.25rem;
  margin: 0 0 0.5rem;
}
.widget-pricing-card > ul {
  list-style-type: none;
  padding: 0;
}
.widget-pricing-card > ul > li {
  display: block;
  font-size: 1rem;
  margin: 0 0 0.5rem;
  margin-bottom: 0.75rem;
}
.widget-pricing-card > a {
  padding: 0.5rem 2rem;
  margin-top: 1.75rem;
  display: block;
  color: #FFFFFF;
  margin: 1.75rem auto 0 auto;
  font-weight: 600;
  width: 10rem;
  border-radius: 4px;
  transition: background-color 0.2s;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.widget-pricing-card > a > span {
  display: block;
  padding: 0;
}
.widget-pricing-card > a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.widget-timeline .timeline-event {
  padding: 0 1rem 1rem 1rem;
  border-left: 1px solid #dee2e6;
  position: relative;
  margin-left: 0.5rem;
}
.widget-timeline .timeline-event-icon {
  position: absolute;
  left: 0;
  top: 0.125rem;
  transform: translateX(-50%);
  color: #6c757d;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  color: #ffffff;
  text-align: center;
}
.widget-timeline .timeline-event-icon i {
  line-height: inherit;
}
.widget-timeline .timeline-event-title {
  font-weight: 600;
  margin: 0 0 0.5rem 0.5rem;
}
.widget-timeline .timeline-event-detail {
  font-size: 0.875rem;
  color: #6c757d;
  margin-left: 0.5rem;
}

.widget-chat ul {
  padding: 1rem;
  margin: 0;
  list-style-type: none;
}
.widget-chat ul li {
  padding: 0.5rem 0;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
}
.widget-chat ul li img {
  width: 36px;
}
.widget-chat ul li div {
  padding: 0.5rem 1rem;
  margin: 0.25rem 0;
  border-radius: 4px;
}
.widget-chat ul li.message-from {
  justify-content: flex-start;
}
.widget-chat ul li.message-from img {
  margin-right: 0.5rem;
}
.widget-chat ul li.message-from div {
  order: 2;
  color: #121212;
  background: #dff0d8;
  border: solid 1px #d6e9c6;
}
.widget-chat ul li.message-own {
  justify-content: flex-end;
}
.widget-chat ul li.message-own img {
  margin-left: 0.5rem;
  order: 2;
}
.widget-chat ul li.message-own div {
  order: 1;
  color: #121212;
  background: #d9edf7;
  border: solid 1px #bce8f1;
}
.widget-chat .new-message {
  height: 40px;
  border-top: 1px solid #dee2e6;
  color: #afafc0;
}
.widget-chat .new-message .message-attachment {
  display: inline-block;
  border-right: 1px solid #dee2e6;
  width: 40px;
  line-height: 40px;
  height: 100%;
  text-align: center;
}
.widget-chat .new-message .message-attachment i {
  line-height: inherit;
  font-size: 24px;
}
.widget-chat .new-message .message-input {
  position: relative;
  top: -4px;
  width: calc(100% - 100px);
  display: inline-block;
}
.widget-chat .new-message .message-input input {
  border: 0 none;
  font-size: 14px;
  width: 100%;
  background: transparent;
  outline: 0 none;
  padding-left: 8px;
}

.widget-person-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.widget-person-list > li {
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
}
.widget-person-list > li:last-child {
  border-bottom: 0 none;
}
.widget-person-list > li .person-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.widget-person-list > li .person-item img {
  margin-right: 0.5rem;
  width: 4rem;
  height: 4rem;
}
.widget-person-list > li .person-item .person-name {
  font-size: 1.25rem;
  font-weight: 700;
}
.widget-person-list > li .person-item .person-subtext {
  color: #6c757d;
}
.widget-person-list > li .person-actions button {
  margin-right: 0.5rem;
}
.widget-person-list > li .person-actions button:last-child {
  margin-right: 0;
}

.widget-user-card {
  padding: 0;
  text-align: center;
}
.widget-user-card .user-card-header {
  position: relative;
  height: 200px;
  background-size: cover;
}
.widget-user-card .user-card-avatar {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 8rem;
  height: 8rem;
  margin-left: -4rem;
  margin-bottom: -4rem;
  border-radius: 50%;
}
.widget-user-card .user-card-body {
  padding: 6em 2rem 2rem 2rem;
}
.widget-user-card .user-card-title {
  font-size: 1.5rem;
  font-weight: 700;
}
.widget-user-card .user-card-subtext {
  color: #6c757d;
}
.widget-user-card .user-card-stats {
  margin-top: 2rem;
}
.widget-user-card .user-card-stats i {
  font-size: 2rem;
  color: #6c757d;
}
.widget-user-card .user-card-stats span {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.exception-body {
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
}
.exception-body .logo {
  width: 50px;
  position: absolute;
  left: 50px;
  top: 50px;
}
.exception-body .exception-content .exception-title {
  font-size: 112px;
  font-weight: 700;
  color: #ffffff;
}
.exception-body .exception-content .exception-detail {
  font-weight: 700;
  color: #ffffff;
  margin: 10px 0 0 0;
}
.exception-body .exception-content > a {
  margin-top: 40px;
  width: 250px;
  display: inline-block;
  text-align: center;
  padding: 0.5rem 1rem;
  color: #ffffff;
  transition: background-color 0.2s;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}
.exception-body .exception-content > a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.exception-body.access {
  background-image: url("/assets/images/pages/bg-access.jpg");
}
.exception-body.notfound {
  background-image: url("/assets/images/pages/bg-404.jpg");
}
.exception-body.error {
  background-image: url("/assets/images/pages/bg-error.jpg");
}

@media (max-width: 640px) {
  .exception-body .exception-content .exception-title {
    font-size: 72px;
  }
}
.layout-config {
  width: 16rem;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  padding: 1rem;
  overflow: auto;
  background: #ffffff;
  z-index: 999;
  border-left: 0 none;
  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}
.layout-config.layout-config-active {
  transform: translateX(0);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.layout-config.layout-config-active .layout-config-content .layout-config-button i {
  transform: rotate(360deg);
}
.layout-config p {
  line-height: 1.5rem;
  color: #6c757d;
}
.layout-config .layout-themes {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.layout-config .layout-themes > div {
  padding: 0.25rem;
}
.layout-config .layout-themes a {
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  display: block;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: transform 0.2s;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.layout-config .layout-themes a i {
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}
.layout-config .layout-themes a:hover {
  transform: scale(1.1);
}

.layout-config-button {
  display: block;
  position: fixed;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  background: #1976D2;
  color: #ffffff;
  text-align: center;
  top: 50%;
  right: 0;
  margin-top: -1.5rem;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transition: background-color 0.2s;
  overflow: hidden;
  cursor: pointer;
  z-index: 999;
  box-shadow: -0.25rem 0 1rem rgba(0, 0, 0, 0.15);
}
.layout-config-button i {
  font-size: 2rem;
  line-height: inherit;
  transform: rotate(0deg);
  transition: transform 1s;
}
.layout-config-button:hover {
  background: #2083e4;
}

/* Add your customizations of the layout styles here */
@font-face {
  font-family: "ma_weather_ms01regular";
  src: url("/assets/fonts/maweather-ms01-webfont.eot");
  src: url("/assets/fonts/maweather-ms01-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/maweather-ms01-webfont.woff") format("woff"), url("/assets/fonts/maweather-ms01-webfont.ttf") format("truetype"), url("/assets/fonts/maweather-ms01-webfont.svg#ma_weather_ms01regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
.wi {
  font-family: "ma_weather_ms01regular";
}

.p-field > * {
  display: block !important;
}

.p-datatable {
  overflow-x: auto;
}
.p-datatable table {
  width: auto !important;
  min-width: 100%;
}