.loader-container {
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
	min-height: 100vh;
	background: #343A40;
}

.loader {
	position: relative;
	margin: auto;
}

.middle {
	top: 0;
	left: 0;
	width: 40px;
	height: 2px;
	background: white;
	position: absolute;
	animation: middleAnimation 2s infinite linear;
	animation-delay: -100ms;
}

.bottom {
	top: 0;
	left: 0;
	position: absolute;
	height: 30px;
	width: 2px;
	background: white;
	border-radius: 2px;
	transform-origin: bottom;
	transform: rotate(45deg);
	animation: bottomAnimation 4s infinite;
}

.bottom:nth-child(1) {
	animation-delay: -1000ms;
}

.bottom:nth-child(2) {
	animation-delay: -2000ms;
}

.bottom:nth-child(3) {
	animation-delay: -3000ms;
}

.bottom-section {
	position: absolute;
	transform: translateY(-16px);
}

.top-section {
	position: absolute;
	transform: translateY(16px);
	transform: scaleY(-1);
}

@keyframes bottomAnimation {
	0% {transform: rotate(45deg) scaleY(1.0);}
	25% {transform: rotate(0) scaleY(0.7);}
	50%{transform: rotate(-45deg) scaleY(1.0);}
	75% {transform: rotate(0) scaleY(0.7);}
	100% {transform: rotate(45deg) scaleY(1.0);}
}

@keyframes middleAnimation {
	0% {transform: translate(-19px, -9px) scaleX(1.0);}
	25% {transform: translate(-19px, -9px) scaleX(0.6);}
	50%{transform: translate(-19px, -9px) scaleX(1.0);}
	75% {transform: translate(-19px, -9px) scaleX(0.7);}
	100% {transform: translate(-19px, -9px) scaleX(1.0);}
}
