* {
    box-sizing: border-box;
}
  
html {
    height: 100%;
    font-size: $fontSize;
}

body {
    font-family: $fontFamily;
    font-weight: 400;
    color: $textColor;
    padding: 0;
    margin: 0;
    min-height: 100%;
    background: $bodyBg;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: $linkColor;
}

.layout-mask {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    width: 100%;
    height: 100%;
    animation-duration: $animationDuration;
    animation-timing-function: $animationTimingFunction;
    animation-fill-mode: forwards;
}

.layout-ajax-loader-icon {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    font-size: 2rem;
    color: $textSecondaryColor;
}